import React, { useEffect, useState, useCallback } from "react";
import DigitalAssets from "./DigitalAssets";
import ModalCards from "./ModalCards"

const Modal = ({ isOpen, onClose, id}) => {
  const [showContent, setShowContent] = useState(isOpen);

  const handleClose = useCallback(() => {
    setShowContent(false); // Set the show content to false first
    setTimeout(() => {
      onClose(); // Close modal after animation
    }, 300); // Ensure this duration matches your transition duration in CSS
  }, [onClose]); 

  useEffect(() => {
    if (isOpen) {
      setShowContent(true);
      const handleScroll = () => {
        if (window.scrollY > 100) {
          handleClose();
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isOpen, handleClose]);

 return (
   <>
     {isOpen && (
       <div className="fixed inset-0 z-50 flex items-end justify-center transition-opacity duration-300 ease-out">
         <button
           className="absolute z-50 top-10 right-5 sm:right-10 lg:right-48 xl:right-80 my-4 bg-gray-200 rounded-full flex justify-center items-center p-2 h-8 w-8 hover:bg-gray-300 transition duration-200 ease-in-out"
           onClick={handleClose}>
           <p>X</p>
         </button>
         <div
           className={`fixed inset-0 bg-black opacity-60 transition-opacity duration-300 ease-out ${
             showContent ? "opacity-100" : "opacity-0"
           }`}
           onClick={handleClose}></div>
         <div
           className={`bg-white w-full md:w-3/4 lg:w-1/2 h-[95vh] p-4 rounded-t-lg overflow-y-auto no-scrollbar transform transition-transform duration-300 ease-out ${
             showContent ? "translate-y-0" : "translate-y-full"
           }`}>
           <DigitalAssets id={id} />
           <ModalCards />
         </div>
       </div>
     )}
   </>
 );
};

export default Modal;
