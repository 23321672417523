import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
// import icon from "../../asstes/Images/icon.png";
import designerfill from "../../asstes/Images/DesignerFill.svg";
import businessfill from "../../asstes/Images/Business Owner Fill.svg";
import marketerfill from "../../asstes/Images/Announcement fill.svg";
import otherfill from "../../asstes/Images/Other Fill.svg";
import designer from "../../asstes/Images/Designer.svg";
import business from "../../asstes/Images/Business Owner.svg";
import marketer from "../../asstes/Images/Announcement.svg";
import other from "../../asstes/Images/Other.svg";

function RegisterStep2({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const location = useLocation();
  const data = location.state;
  // const plans = location.plans;
  const navigate = useNavigate();

  const [role, setRole] = useState("Others");

  const submitFunction = e => {
    e.preventDefault();
    navigate("/registerstep3", { state: { data, role } });
    console.log(role);
    console.log(data);

  };

  const options = [
    {
      value: "Designer",
      label: "Designer",
      img: designer,
      imgFill: designerfill,
    },
    {
      value: "Business Owner",
      label: "Business\nOwner",
      img: business,
      imgFill: businessfill,
    },
    {
      value: "Marketer",
      label: "Marketer",
      img: marketer,
      imgFill: marketerfill,
    },
    { value: "Others", label: "Others", img: other, imgFill: otherfill },
  ];

  return (
    <div className="">
      <section className="max-w-screen-xl h-screen flex flex-col items-center justify-center bg-white mx-auto my-auto px-4 md:px-8">
        <nav className=" w-screen fixed bg-white top-0 px-4 py-2.5">
          <div className="max-w-screen-xl mx-auto">
            <Link to="/" className="flex">
              <img
                src={logo}
                className="h-6 mr-6 sm:h-9"
                alt="CronMedia Logo"
              />
            </Link>
          </div>
        </nav>

        <div className="w-full max-w-2xl bg-white p-8 ">
          <div className="flex flex-col justify-between h-full">
            <div>
              <h2 className="py-4 text-2xl font-bold text-black font-mango">
                Which role best describes you?
              </h2>
              <form onSubmit={submitFunction}>
                <div className="flex flex-wrap gap-4 py-4 items-center text-sm">
                  {options.map(option => (
                    <label
                      key={option.value}
                      className={`rounded-xl flex text-xs text-center flex-col items-center px-6 py-2 h-24 w-24 border ${
                        role === option.value
                          ? "text-gray border-[#4e22ff]"
                          : "bg-white text-black border-black"
                      } cursor-pointer transform hover:scale-105 transition duration-300`}>
                      <input
                        type="radio"
                        name="role"
                        value={option.value}
                        checked={role === option.value}
                        onChange={e => setRole(e.target.value)}
                        className="hidden"
                      />
                      {role === option.value ? (
                        <img
                          className="p-2 z-0"
                          src={option.imgFill}
                          alt={option.label}
                        />
                      ) : (
                        <img
                          className="p-2 z-0"
                          src={option.img}
                          alt={option.label}
                        />
                      )}
                      {option.label}
                    </label>
                  ))}
                </div>
                <div>
                  <div className="w-full bg-gray-200 rounded-full h-1.5 my-4">
                    <div className="w-[60%] bg-gradient-to-r from-[#1c9cff] to-[#4e22ff] h-1.5 rounded-full "></div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="bg-gradient-to-r font-semibold rounded-full from-[#4e22ff] to-[#1c9cff] text-white px-8 py-1 transform hover:scale-105 transition duration-300">
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RegisterStep2;
