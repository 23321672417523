import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

const Plans = ({ role, plans, email, img, name, googlesub, googleflag }) => {
  const [, setPricing] = useState("");
  const [checked, setChecked] = useState(false);
  const notification = "1";
  const navigate = useNavigate();

  const handleSubmit = newPricing => {
    setPricing(newPricing);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("googlesub", googlesub);
    formData.append("img", img); 
    formData.append("plans", plans);
    formData.append("role", role);
    formData.append("pricing", newPricing);
    formData.append("googleflag", googleflag);
    formData.append("notification", notification);
    

    axios
      .post(
        "https://www.cron-media.com:8080/registerWithGoogle.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        console.log(response.data);
        console.log(email);
        if (email == null || undefined) {
          navigate("/login")
        }
        else {
          navigate("/dashboard", { state: { email } });
        }
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  };

  return (
    <section className="mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-black sm:text-5xl font-mango">
            {/* Pricing Plans */}
          </h2>
          <p className="mt-4 flex justify-center items-center gap-4 text-xl text-black">
            Monthly
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={!checked ? "checked" : null}
                onClick={() => setChecked(!checked)}
                value=""
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-xl text-gray-900 ">Yearly</span>
              {!checked ? (
                <span className="ms-3 text-sm font-medium rounded-xl p-1 bg-green-400 ">
                  25%
                </span>
              ) : (
                <span className=" invisible ms-3 text-sm font-medium rounded-xl p-1 bg-green-400 ">
                  25%
                </span>
              )}
            </label>
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="bg-white rounded-2xl shadow-xl shadow-[#d9dfff] p-6 transform hover:scale-105 hover:shadow-2xl hover:shadow-[#bec8ff] transition duration-300 border-2 border-slate-200">
            <div className="mb-8">
              <h3 className="text-3xl py-3 font-semibold text-black font-mango">
                Free
              </h3>
              <h3 className="text-6xl font-bold text-slate-400">₹0</h3>
              <p className="mt-4 text-gray-400">
                Get started with our basic features.
              </p>
            </div>
            <ul className="mb-8 space-y-4 text-black font-medium text-lg">
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-green-500 mr-2 p-1 rounded-full border"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>1 user account</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-green-500 mr-2 p-1 rounded-full border"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>10 transactions per month</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-green-500 mr-2 p-1 rounded-full border"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Basic support</span>
              </li>
            </ul>
            <div className="flex justify-center">
              <button
                type="submit"
                onClick={() => handleSubmit("free")}
                className="w-[90%] bg-slate-400 py-4 rounded-full font-semibold text-white">
                Get Started
              </button>
            </div>
          </div>

          {/* <!-- Starter Plan --> */}
          <div className="bg-white rounded-2xl shadow-xl shadow-[#d9dfff] p-6 transform hover:scale-105 hover:shadow-2xl hover:shadow-[#bec8ff] transition duration-300 border-2 border-slate-200">
            <div className="mb-8">
              <h3 className="text-3xl py-3 font-semibold text-black font-mango">
                Plus
              </h3>
              <h3 className=" text-6xl font-bold text-[#4e22ff]">
                {!checked ? "₹499" : "₹299"}
              </h3>
              <p className="mt-4 text-gray-400">
                Get started with our basic features.
              </p>
            </div>

            <ul className="mb-8 space-y-4 text-black font-medium text-lg">
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-white rounded-full bg-[#4e22ff] p-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>5 user accounts</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-white rounded-full bg-[#4e22ff] p-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>100 transactions per month</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-white rounded-full bg-[#4e22ff] p-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Priority support</span>
              </li>
            </ul>
            <div className="flex justify-center">
              <button
                type="submit"
                onClick={() => handleSubmit("plus")}
                className="w-[90%] bg-[#4e22ff] py-4 rounded-full font-semibold text-white">
                Get Started
              </button>
            </div>
          </div>

          {/* <!-- pro Plan --> */}
          <div className="bg-white rounded-2xl shadow-xl shadow-[#d9dfff] p-6 transform hover:scale-105 hover:shadow-2xl hover:shadow-[#bec8ff] transition duration-300 border-2 border-slate-200">
            <div className="mb-8">
              <h3 className="text-3xl py-3 font-semibold text-black font-mango">
                Pro
              </h3>
              <h3 className="text-6xl font-bold">
                {!checked ? "₹999" : "₹699"}
              </h3>
              <p className="mt-4 text-gray-400">
                Get started with our basic features.
              </p>
            </div>
            <ul className="mb-8 space-y-4 text-black font-medium text-lg">
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-white rounded-full bg-black p-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Unlimited user accounts</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-white rounded-full bg-black p-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>Unlimited transactions</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="h-6 w-6 text-white rounded-full bg-black p-1 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>24/7 support</span>
              </li>
            </ul>
            <div className="flex justify-center">
              <button
                type="submit"
                onClick={() => handleSubmit("pro")}
                className="w-[90%] bg-black py-4 rounded-full font-semibold text-white">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plans;
