import React from "react";
import { Link } from "react-router-dom";

const AdminNavbar = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-lg">
          <Link to="/adminPanel" className="text-gray-300 hover:text-white">
            Dashboard
          </Link>
        </div>
        <div className="text-white text-lg">
          <Link to="/" className="text-gray-300 hover:text-white">
            Home
          </Link>
        </div>
        <div className="flex space-x-4">
          <Link to="/adminTemplates" className="text-gray-300 hover:text-white">
            Templates
          </Link>
          <Link
            to="/adminCollections"
            className="text-gray-300 hover:text-white">
            Collections
          </Link>
          <Link to="/adminUsers" className="text-gray-300 hover:text-white">
            Users
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;