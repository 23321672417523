import { useRef, useState } from "react";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className="group border hover:border-[#4e22ff] mt-5 overflow-hidden  rounded-xl "
      key={idx}
      onClick={handleOpenAnswer}
    >
      <h4 className="cursor-pointer text-center p-5 flex items-center justify-between text-lg text-gray-700 group-hover:bg-gradient-to-br from-[#1c9cff] to-[#4e22ff] group-hover:bg-clip-text group-hover:text-transparent font-medium">
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}
      >
        <div>
          <p className="text-gray-500 px-5 ">{faqsList.a}</p>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const faqsList = [
    {
      q: "What are responsive templates?",
      a: "Responsive templates are pre-designed formats used for creating various digital assets like website layouts, press release layouts, email newsletter designs, and marketing materials. Allowing users to easily modify content, images, and branding elements that reflect their brand identity seamlessly. ",
    },
    {
      q: "How can templates benefit my brand?",
      a: "Templates can significantly benefit your brand by providing consistency, efficiency, and professionalism across your marketing materials and communications. It helps to build a uniform visual identity, ensuring that your logo, colors, fonts, and overall design elements remain consistent. Strengthening brand recognition and recall.",
    },
    {
      q: "Are templates customizable?",
      a: "Yes, we offer extensive customization options for every template layout. You can freely modify colors, fonts, content, and visuals to match your brand identity and unique style.",
    },
    {
      q: "Are these templates optimized for all devices?",
      a: "Yes, our templates are designed in a full-optimized manner. Ensuring that these layouts perform well and look great on a variety of devices, from desktops to mobile phones.",
    },
    {
      q: "Are these templates free?",
      a: "Yes. CronMedia's free version allows you to make 10 translations a month. However, on joining the Pro version, you can access premium templates with unlimited downloads.",
    },
  ];

  return (
    <section
      id="faq"
      className="leading-relaxed  my-12 px-5 xl:px-0 max-w-screen-xl mx-auto">
      <div className="space-y-3 text-center">
        <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
          FAQ
        </span>
        <h1 className="text-3xl text-gray-800 font-semibold font-mango">
          Frequently Asked Questions
        </h1>
        <p className="text-gray-600 max-w-lg mx-auto text-lg font-clash">
          Answered all frequently asked questions, Still confused? feel free to
          contact us.
        </p>
      </div>
      <div className="mt-14 text-left max-w-screen-xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard key={idx} idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};

export default Faq;