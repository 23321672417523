import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdDownloadForOffline } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Modal from "../Details/Modal";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const Cards = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collection, setCollection] = useState([]);
  const userID = Cookies.get("userID");

  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTag = e => {
    const value = e.target.textContent;
    Cookies.set("TagSearch", value);

    if (Cookies.get("status") === "active") {
      navigate("/dashboard");
    } else {
      navigate("/template");
    }
  };

  useEffect(() => {
    axios
      .get("https://www.cron-media.com:8080/adminTemplates.php?id=")
      .then(output => {
        console.log(output.data);
        setData(output.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  // Determine the number of items per row
  const itemsPerRow = 3; // Number of columns in your grid
  const numberOfRows = 2; // Number of rows you want to display
  const visibleItems = data.slice(0, itemsPerRow * numberOfRows);

  // Fetch user's collection
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const response = await axios.get(
          `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}`
        );
        setCollection(response.data.templateCollection || []);
      } catch (error) {
        console.error("Error fetching template collection:", error);
      }
    };

    fetchCollection();
  }, [userID]);

  const addTemplate = id => {
    try {
      axios.post(
        `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}`,
        {
          userID,
          newTemplates: id,
        }
      );
      setCollection(prevCollection => [...prevCollection, id]);
    } catch (error) {
      console.error("Error adding template:", error);
    }
  };

  const addDownload = id => {
    try {
      axios.post(
        `https://www.cron-media.com:8080/Download.php?userID=${userID}`,
        {
          userID,
          newDownload: id,
        }
      );
      setCollection(prevCollection => [...prevCollection, id]);
    } catch (error) {
      console.error("Error adding template:", error);
    }
  };

  // const deleteTemplate = async () => {
  //   try {
  //     await axios.put(
  //       `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}&tempID=${id}`,
  //       {
  //         userID,
  //         tempID: id,
  //       }
  //     );
  //     setCollection(prevCollection =>
  //       prevCollection.filter(templateID => templateID !== id)
  //     );
  //   } catch (error) {
  //     console.error("Error deleting template:", error);
  //   }
  // };

  const handleClick = id => {
    setId(id);
    addTemplate(id);
  };

  return (
    <>
      <section className="leading-relaxed mt-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {visibleItems.map((value, index) => (
            <div
              key={index}
              className="w-full h-auto rounded-2xl shadow-lg bg-white cursor-pointer transition-all duration-200 ease-in hover:shadow-xl hover:scale-[1.01] border border-gray-300">
              <div
                onClick={() => {
                  handleOpenModal();
                  setId(value.ID);
                }} className=" border-b border-gray-300">
                {value.Small_preview.length !== 0 ? (
                  <img
                    src={`https://www.cron-media.com:8080/Templates/Images/${value.Small_preview[0]}`}
                    alt={value.Template_Name}
                    className="w-full h-[60%] rounded-t-2xl object-cover"
                  />
                ) : (
                  <img
                    src="https://via.placeholder.com/300X200"
                    loading="lazy"
                    alt={value.Template_Name}
                    className="w-full h-[60%] rounded-t-2xl object-cover"
                  />
                )}
              </div>
              <div className="px-4 pb-4">
                <div className="flex space-x-2 my-2 line-clamp-1">
                  {value.Tags?.map((tag, index) => (
                    <span
                      key={index}
                      onClick={handleTag}
                      className="text-xs text-nowrap border border-gray-300 text-black px-2 py-1 rounded-full h-fit transition-all duration-75 ease-in-out hover:bg-[#3d4bfe] hover:text-white hover:border-white">
                      {tag}
                    </span>
                  ))}
                </div>
                <div
                  onClick={() => {
                    handleOpenModal();
                    setId(value.ID);
                  }}>
                  <h4 className="text-lg font-medium mb-2 line-clamp-1">
                    {value.Template_Name}
                  </h4>
                  <p className="text-gray-700 text-sm mb-4 line-clamp-2">
                    {value.Template_Desc}
                  </p>
                </div>

                <div className="flex justify-between items-center">
                  <span className="text-sm font-bold text-black">
                    {value.Assets} Assets
                  </span>
                  <div className="flex gap-2 items-center">
                    {Cookies.get("status") === "active" ? (
                      collection.includes(value.ID) ? (
                        <Tooltip title="Added" placement="bottom">
                          <IconButton>
                            <FaCheckCircle size={24} color="green" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add" placement="bottom">
                          <IconButton onClick={() => handleClick(value.ID)}>
                            <FaCirclePlus size={24} color="black" />
                          </IconButton>
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip title="Add" placement="bottom">
                        <IconButton>
                          <Link to="/login">
                            <FaCirclePlus size={24} color="black" />
                          </Link>
                        </IconButton>
                      </Tooltip>
                    )}
                    {Cookies.get("status") === "active" ? (
                      <Tooltip title="Download" placement="bottom">
                        <a
                          href={`https://www.cron-media.com:8080/Templates/Zip/${value.Template_File}`}
                          download
                          onClick={() => addDownload(value.ID)}>
                          <MdDownloadForOffline size={28} />
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Download" placement="bottom">
                        <Link to="/login">
                          <MdDownloadForOffline size={28} />
                        </Link>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} id={id} />
    </>
  );
};

export default Cards;
