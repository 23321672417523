import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ResetPass({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const email = location.state;
  const navigate = useNavigate();

  //password validator
  // useEffect(() => {

  // }, [password, confirmPassword]);

  // const handlePasswordChange = e => {
  //   const value = e.target.value;
  //   setPassword(value);
  // };

  const submitPassowrd = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      await axios
        .post("https://www.cron-media.com:8080/updatePassword.php", {
          email,
          password,
        })
        .then(res => {
          console.log(res);
          navigate("/login");
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <nav className="w-full  bg-white top-0 px-4 py-2.5  z-50">
        <div className="max-w-screen-xl  mx-auto">
          {/* image and buttons */}

          <Link to="/" className="flex">
            <img src={logo} className="h-6 mr-6 sm:h-9" alt="CronMedia Logo" />
          </Link>
        </div>
      </nav>

      <section className="bg-white mx-auto w-full max-w-screen-xl flex flex-col md:flex-row items-center justify-center mt-32">
        <div className="md:w-[70%] flex flex-col lg:flex-row bg-white shadow-xl rounded-2xl overflow-hidden">
          <div className="w-full lg:w-[60%] p-10 bg-gradient-to-bl from-[#1c9dff97] to-[#4e22ff97] text-white flex items-center justify-center">
            <div>
              <p className="text-3xl font-bold font-mango">
                Be the Change you wish to see in the world.
              </p>
              <p className="mt-4 text-xl">- Mahatma Gandhi</p>
            </div>
          </div>
          <div className="w-full lg:w-[40%] p-10 bg-white flex flex-col justify-center">
            <h2 className="text-2xl font-medium mb-4">
              Welcome Back to CronMedia
            </h2>
            {/* form */}
            <form>
              <div className="mb-4">
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  placeholder="Password"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  required
                  placeholder="Confirm Password"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                />
              </div>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <Link
                type="submit"
                onClick={() => submitPassowrd()}
                className="bg-gradient-to-bl from-[#1c9dffcf] to-[#4e22ffcc] text-white rounded-lg px-4 py-2 w-full">
                Reset Password
              </Link>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPass;
