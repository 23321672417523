import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
// import Cookies from "js-cookie";
import Homeindex from "./Component/HomePage/HomeIndex";
import NotFoundPage from "./Component/HomePage/404NotFound";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import Loginindex from "./Component/ProfilePage/Login/LoginIndex";
import Pricingindex from "./Component/PricingPage/PricingIndex";
import Registerindex from "./Component/ProfilePage/Register/RegisterIndex";
import RegisterStep2 from "./Component/ProfilePage/Register/RegisterStep2";
import RegisterStep3 from "./Component/ProfilePage/Register/RegisterStep3";
import DashboardIndex from "./Component/Userpanel/Dashboard/DashboardIndex";
import TemplateIndex from "./Component/Templates/TemplatIndex";
import PasswordForget from "./Component/ProfilePage/Login/PasswordForget";
import ResetPass from "./Component/ProfilePage/Login/ResetPassword";
import Contact from "./Component/ContactUs/ContactUs";
import IndividualBlog from "./Component/HomePage/IndividualBlog";
import AdminLogin from "./Admin/AdminLogin";
import AdminPanel from "./Admin/AdminPanel";
import AdminTemplates from "./Admin/Templates/AdminTemplates";
import AddTemplate from "./Admin/Templates/AddTemplate";
import UpdateTemplate from "./Admin/Templates/UpdateTemplate";
import AdminCollections from "./Admin/Collections/AdminCollections";
import AddCollections from "./Admin/Collections/AddCollections";
import UpdateCollections from "./Admin/Collections/UpdateCollections";
import AdminUsers from "./Admin/AdminUsers";
import DownloadPage from "./Component/Userpanel/Downloads/DownloadPage";
import UserProfile from ".//Component/Userpanel/Profile/UserProfile";
import ScrollToTop from "./Component/HomePage/ScrollToTop";
import TermsConditions from "./Component/HomePage/TermsConditons";
import PrivacyPolicy from "./Component/HomePage/PrivacyPolicy";
import RefundPolicy from "./Component/HomePage/RefundPolicy";
import AboutUs from "./Component/HomePage/AboutUs";

function App() {
  const [showFooter, setShowFooter] = useState(true);
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get("https://www.cron-media.com:8080/adminTemplates.php?id=")
      .then(output => {
        // console.log(output.data);
        setData(output.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  // const status = Cookies.get('status');
  // const user = localStorage.getItem("userLogin");
  // console.log(user);

  return (
    <div>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {showFooter ? <Header /> : null}
      <ScrollToTop />

      <Routes>
        <Route
          path="/"
          element={
            <Homeindex
              data={data}
              setData={setData}
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="*"
          element={
            <NotFoundPage
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/template"
          element={
            <TemplateIndex
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/pricing"
          element={
            <Pricingindex
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/login"
          element={
            <Loginindex showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/register"
          element={
            <Registerindex
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/registerstep2"
          element={
            <RegisterStep2
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/registerstep3"
          element={
            <RegisterStep3
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <DashboardIndex
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/profile"
          element={
            <UserProfile
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/download"
          element={
            <DownloadPage
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/individualBlog"
          element={
            <IndividualBlog
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/forget"
          element={
            <PasswordForget
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/reset"
          element={
            <ResetPass showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/adminLogin"
          element={
            <AdminLogin showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/adminPanel"
          element={
            <AdminPanel showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/adminTemplates"
          element={
            <AdminTemplates
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/addTemplate"
          element={
            <AddTemplate
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/updateTemplate/:id"
          element={
            <UpdateTemplate
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/adminCollections"
          element={
            <AdminCollections
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/addCollection"
          element={
            <AddCollections
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/updateCollection/:id"
          element={
            <UpdateCollections
              showFooter={showFooter}
              setShowFooter={setShowFooter}
            />
          }
        />
        <Route
          path="/adminUsers"
          element={
            <AdminUsers showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/TermsConditions"
          element={
            <TermsConditions showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/PrivacyPolicy"
          element={
            <PrivacyPolicy showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/RefundPolicy"
          element={
            <RefundPolicy showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
        <Route
          path="/AboutUs"
          element={
            <AboutUs showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        />
      </Routes>

      {showFooter ? <Footer /> : null}
    </div>
  );
}

export default App;
