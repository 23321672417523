// import clients from "../asstes/Images/clients.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import glits from "../asstes/Images/glitstudio-351x152.webp";
import oyo from "../asstes/Images/oyo-351x152.webp";
import zoom from "../asstes/Images/zoomcar-351x152.webp";
import vaaree from "../asstes/Images/vaaree-351x152.webp";
import skilcamp from "../asstes/Images/skilcamp-351x152.webp";

const Clients = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items:5,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        },
      };
  return (
    <div className="px-5 xl:px-0 max-w-screen-xl mx-auto">
      {/* <img src={clients} alt="clients" /> */}
      <Carousel
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            arrows={false}
          >
            <div className="max-w-sm my-4  p-6  mx-3">
              <div className="flex justify-center mb-4">
                {/* <MdOutlineAccountCircle size={80} /> */}
                <img src={glits} size={80} alt="Glitstudio"/>
              </div>
              
            </div>

            <div className="max-w-sm my-4 p-6  mx-3">
              <div className="flex justify-center mb-4">
                {/* <MdOutlineAccountCircle size={80} /> */}
                <img src={skilcamp}  alt="Skilcamp"/>
              </div>
  
            </div>

            <div className="max-w-sm  p-6 mx-3">
              <div className="flex justify-center mb-4">
                {/* <MdOutlineAccountCircle size={80} /> */}
                <img src={zoom} size={80} alt="Zoomcar"/>
              </div>
          
            </div>

            <div className="max-w-sm  p-6 mx-3">
              <div className="flex justify-center mb-4">
                {/* <MdOutlineAccountCircle size={80} /> */}
                <img src={oyo} size={80} alt="Oyo"/>
              </div>
              
            </div>

            <div className="max-w-sm  p-6 mx-3">
              <div className="flex justify-center mb-4">
                {/* <MdOutlineAccountCircle size={80} /> */}
                <img src={vaaree} size={80} alt="Vararee"/>
              </div>     
            </div>

            
          </Carousel>
    </div>
  );
};

export default Clients;
