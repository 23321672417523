import axios from "axios";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Cookies from "js-cookie";
import { MdDownloadForOffline } from "react-icons/md";
// import { TiDelete } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
// import toast from "react-hot-toast";
import empty from "../../asstes/Images/5928293_2953960.svg";
import Modal from "../../Details/Modal";

const Download = ({ isOpen, catindex, searchdata, count_n_category }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [sideindex, setSideindex] = useState("");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [id, setId] = useState();
  const [collection, setCollection] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemsPerPage = 8; // Number of cards per page

  const userID = Cookies.get("userID");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTag = e => {
    const value = e.target.textContent;
    Cookies.set("TagSearch", value);
    window.location.reload();
  };

  useEffect(() => {
    const getSearchTerm = () => {
      const searchTermCookie = Cookies.get("searchterm");
      const tagSearchCookie = Cookies.get("TagSearch");

      if (searchTermCookie && searchTermCookie !== "undefined") {
        return searchTermCookie;
      } else if (tagSearchCookie && tagSearchCookie !== "undefined") {
        return tagSearchCookie;
      } else {
        return searchdata;
      }
    };

    const getCategory = () => {
      const category = Cookies.get("categoryIndex");

      if (category) {
        return category;
      } else {
        return catindex;
      }
    };

    const searchTerm = getSearchTerm();
    const categoryIndex = getCategory();

    if (searchTerm !== "undefined" && searchTerm !== undefined) {
      setSearch(searchTerm);
      Cookies.remove("searchterm");
      Cookies.remove("TagSearch");
    } else {
      setSearch(searchdata);
    }

    if (categoryIndex !== undefined) {
      setSideindex(categoryIndex);
      Cookies.remove("categoryIndex");
    } else {
      setSideindex(catindex);
    }
  }, [catindex, searchdata]);

  useEffect(() => {
    axios
      .get(
        `https://www.cron-media.com:8080/DownloadDisplay.php?id=${userID}&categoryid=${sideindex}`
      )
      .then(output => {
        const fetchedData = Array.isArray(output.data.templates)
          ? output.data.templates
          : [];
        const filtered = fetchedData.filter(item => {
          const templateMatch = item.Template_Name.toLowerCase().includes(
            search.toLowerCase()
          );
          const tagsMatch = item.Tags?.some(tag =>
            tag.toLowerCase().includes(search.toLowerCase())
          );
          return templateMatch || tagsMatch;
        });
        setFilteredData(filtered);
        setCurrentPage(0);
        setCount(output.data.count);
      })
      .catch(error => {
        console.error(error);
      });
  }, [count, search, sideindex, userID]);

  count_n_category(count, filteredData, search);

 useEffect(() => {
   const endOffset = (currentPage + 1) * itemsPerPage;
   setCurrentItems(
     Array.isArray(filteredData)
       ? filteredData.slice(currentPage * itemsPerPage, endOffset)
       : []
   );
 }, [currentPage, filteredData, itemsPerPage]);

 useEffect(() => {
   window.scrollTo(0, 0);
 }, [currentPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = newPage => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Fetch user's collection
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const response = await axios.get(
          `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}`
        );
        setCollection(response.data.templateCollection || []);
      } catch (error) {
        console.error("Error fetching template collection:", error);
      }
    };

    fetchCollection();
  }, [userID]);
  console.log(collection);

  const addTemplate = id => {
    try {
      axios.post(
        `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}`,
        {
          userID,
          newTemplates: id,
        }
      );
      setCollection(prevCollection => [...prevCollection, id]);
    } catch (error) {
      console.error("Error adding template:", error);
    }
  };

  const handleClick = id => {
    setId(id);
    addTemplate(id);
  };

  return (
    <>
      <section className="leading-relaxed mt-12 z-0">
        {currentItems.length === 0 ? (
          <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-5">
            <div className="max-w-md text-center">
              <img
                src={empty}
                alt="404 illustration"
                className="w-full h-auto"
              />
              <h1 className="text-4xl font-bold text-gray-800 mb-4">
                No Data Yet 😣
              </h1>
              <p className="text-gray-600 mb-8">
                The data you are looking for might have been removed, had its
                name changed, or is temporarily unavailable.
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`grid grid-cols-1 sm:grid-cols-2 ${
              isOpen
                ? "md:grid-cols-2 lg:grid-cols-3"
                : "md:grid-cols-3 lg:grid-cols-4"
            } gap-6`}>
            {currentItems.map((value, index) => (
              <div
                key={index}
                className="w-full h-auto rounded-2xl shadow-lg bg-white cursor-pointer transition-all duration-200 ease-in hover:shadow-xl hover:scale-[1.01] border border-gray-300">
                <div
                  onClick={() => {
                    handleOpenModal();
                    setId(value.ID);
                  }}
                  className=" border-b border-gray-300">
                  {value.Small_preview.length !== 0 ? (
                    <img
                      src={`https://www.cron-media.com:8080/Templates/Images/${value.Small_preview[0]}`}
                      alt={value.Template_Name}
                      loading="lazy"
                      className="w-full h-[60%] rounded-t-2xl object-cover"
                    />
                  ) : (
                    <img
                      src="https://via.placeholder.com/300X200"
                      alt={value.Template_Name}
                      loading="lazy"
                      className="w-full h-[60%] rounded-t-2xl object-cover"
                    />
                  )}
                </div>
                <div className="px-4 pb-4">
                  <div className="flex space-x-2 my-2 line-clamp-1">
                    {value.Tags?.map((tag, index) => (
                      <span
                        key={index}
                        onClick={handleTag}
                        className="text-xs text-nowrap border border-gray-300 text-black px-2 py-1 rounded-full h-fit transition-all duration-75 ease-in-out hover:bg-[#3d4bfe] hover:text-white hover:border-white">
                        {tag}
                      </span>
                    ))}
                  </div>
                  <div
                    onClick={() => {
                      handleOpenModal();
                      setId(value.ID);
                    }}>
                    <h4 className="text-lg font-medium mb-2 line-clamp-1">
                      {value.Template_Name}
                    </h4>
                    <p className="text-gray-700 text-sm mb-4 line-clamp-2">
                      {value.Template_Desc}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-bold text-black">
                      {value.Assets} Assets
                    </span>
                    <div className="flex gap-2 items-center">
                      {collection.includes(value.ID) ? (
                        <Tooltip title="Add" placement="bottom">
                          <IconButton>
                            <FaCheckCircle size={24} color="green" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add" placement="bottom">
                          <IconButton>
                            <FaCirclePlus
                              size={24}
                              color="black"
                              onClick={() => handleClick(value.ID)}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Download" placement="bottom">
                        <a
                          href={`https://www.cron-media.com:8080/Templates/Zip/${value.Template_File}`}
                          download>
                          <MdDownloadForOffline size={28} />
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="my-10 flex justify-center gap-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
            className="w-10 h-10 px-3 py-1 border rounded-full transition duration-300 ease-in-out hover:border-[#3d48fe]">
            «
          </button>
          {[...Array(totalPages).keys()].map(page => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`w-10 h-10 px-3 py-1 border rounded-full transition duration-300 ease-in-out ${
                page === currentPage
                  ? "bg-gradient-to-r from-blue-500 to-purple-500 text-white"
                  : "hover:border-[#3d48fe]"
              }`}>
              {page + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
            className="w-10 h-10 px-3 py-1 border rounded-full transition duration-300 ease-in-out hover:border-[#3d48fe]">
            »
          </button>
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} id={id} />
    </>
  );
};

export default Download;
