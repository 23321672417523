import { Link } from "react-router-dom";

const AboutUs = ({ showFooter, setShowFooter }) => {
  setShowFooter(true);

  return (
    <div className="bg-slate-200 py-6 px-10">
      <main className="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-6xl">
        <section className="mb-8">
          <h2 className="text-xl font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            Get to Know Us
          </h2>
          <p className="text-gray-700 mb-4">
            Enter CronMedia, where groundbreaking ideas meet creative
            brilliance. Delivering a hub for every visual need, empowering
            creators who push boundaries for digital experiences and never
            settle.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            Vision
          </h3>
          <p className="text-gray-700 mb-4">
            Transforming Business into Busyness with innovative design and
            impactful visuals.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            Mission
          </h3>
          <p className="text-gray-700 mb-4">
            Pursuing digital excellence by chasing Better assets. Better
            workflow. Better output.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            What We Deliver
          </h3>
          <ul className="list-disc pl-6 text-gray-700 mb-4">
            <li className="mb-2">
              <strong>Digital Marketing Promotions:</strong> Bringing innovative
              visuals for your educational campaigns, product promotions, and
              seasonal collections.
            </li>
            <li className="mb-2">
              <strong>Minimalist & Creative Designs:</strong> Get your hands on
              sleek, modern, and minimalist designs, digital infographics, and
              festive campaigns.
            </li>
            <li className="mb-2">
              <strong>Industry-Specific Campaigns:</strong> Bring culinary
              delights for food & beverage campaigns, inspire exploration for
              travel & tourism, entice about the latest trends in fashion, and
              promote healthy lifestyles with wellness marketing.
            </li>
            <li className="mb-2">
              <strong>Multi-Channel Advertising:</strong> Combines multiple ads
              and creative agency services for broad reach and impact.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            Our Client Stories
          </h3>
          <p className="text-gray-700 mb-4">
            Listen to the voices of those who know us best.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            CronMedia Help
          </h3>
          <p className="text-gray-700 mb-4">
            Read out the <Link to="/#faq" className=" underline underline-offset-4">FAQ page</Link> along with the
            step-by-step guide to maximize the ROI on your services. Still, if
            you face any issues, quickly reach out to us. We await to help you
            at the utmost!
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            Never Stop Learning!
          </h3>
          <p className="text-gray-700 mb-4">
            Dive into our blog for the latest insights, game-changing
            strategies, and creative sparks. Start leading the way, push
            boundaries, and reshape your audience interactions.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-lg font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            Be a CronMedia Insider!
          </h3>
          <p className="text-gray-700 mb-4">
            Become an early bird member and stay on top with CronMedia!
          </p>
          {/* Uncomment if you want to add a subscription button
          <div className="mt-8">
            <a
              href="#"
              className="inline-block bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700">
              Subscribe Now
            </a>
          </div> 
          */}
        </section>
      </main>
    </div>
  );
};

export default AboutUs;
