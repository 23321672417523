import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const withAuth = WrappedComponent => {
    return props => {
    const status = Cookies.get('status');
    if (status !== 'active') {
         return <Navigate to="/login" />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
