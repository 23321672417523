import logo from "../asstes/Images/footerlogowhite.svg";
import facebook from "../asstes/Images/Facebook.svg";
import twitter from "../asstes/Images/Twitter.svg";
import youtube from "../asstes/Images/YouTube.svg";
import instagram from "../asstes/Images/Instagram.svg";
import linkedIn from "../asstes/Images/LinkedIn.svg";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useState } from "react";
import axios from "axios";

function Footer() {
  const [email, setEmail] = useState("");
  const [accept, setAccept] = useState("no");

  const handleEmail = async () => {
    const toastId = toast.loading("Subscribing");

    try {
      // Assuming `email` and `accept` are state variables
      const response = await axios.post(
        "https://www.cron-media.com:8080/subscriber.php",
        {
          email: email,
          accept: accept,
        }
      );

      console.log(response);
      toast.success("Subscribed 🎉", {
        id: toastId,
      });

      // Optionally, navigate to another page if needed
      // navigate("/dashboard");
    } catch (error) {
      console.error(error);
      toast.error("Not Subscribed 💀", {
        id: toastId,
      });
    }

    // Resetting form or state variables if needed
    setAccept(""); // Clear the acceptance status
    setEmail(""); // Clear the email input
    console.log(email);
    console.log(accept);
  };

  return (
    <div>
      <footer className=" sticky left-0 bottom-0 w-full bg-gray-900">
        <div className="mx-auto w-full pt-8 ">
          <div className="px-5 xl:px-0">
            <div className="max-w-screen-xl md:flex justify-between mx-auto bg-[#4e22ff] text-white p-8 rounded-lg ">
              <div className="md:w-3/4">
                <p className="text-2xl py-2 font-semibold">Join The Fun!</p>
                <p className="text-sm max-w-md">
                  Take 15% Off on your 1st purchase by signing up for our
                  newsletters. Hear the Tick.Tock. about the new drops.
                </p>
              </div>

              <div className="md:w-2/6 mt-4 md:mt-0">
                <div className="p-2 flex flex-col lg:flex-row items-center text-black bg-white rounded-md">
                  <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="p-2 w-full border rounded-md mr-2 mb-2 lg:mb-0 focus:ring-1 focus:outline-none"
                    placeholder="Your Email Address"
                    required
                  />

                  <button
                    type="button"
                    onClick={handleEmail}
                    className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300  font-medium text-sm px-5 py-2.5 text-center rounded-md w-full lg:w-1/2">
                    Subscribe
                  </button>
                </div>
                <div className="flex items-start pt-2">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      type="checkbox"
                      value="Yes"
                      onChange={e => setAccept(e.target.value)}
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 "
                      required
                    />
                  </div>
                  <label
                    htmlFor="remember"
                    className="ml-2 text-sm text-white dark:text-gray-300">
                    By clicking Subscribe you're confirming that you agree with
                    our Terms and Conditions.
                  </label>
                </div>
              </div>
            </div>

            <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center gap-8 py-4 lg:py-8">
              <div className="block">
                <Link to="/">
                  <img src={logo} alt="logo" width="150" />
                </Link>
              </div>
              <div className="text-white flex flex-col md:flex-row items-center gap-4 md:gap-8">
                <Link to="/template" className="text-sm">
                  Templates
                </Link>
                <Link to="/AboutUs" className="text-sm">
                  About Us
                </Link>
                <Link to="/RefundPolicy" className="text-sm">
                  Refund Policy
                </Link>
                <Link to="/contact" className="text-sm">
                  Contact Us
                </Link>
              </div>
              {/* <div className="sm:hidden flex justify-center pt-4 md:pt-0">
              <img src={logo} alt="logo" width="150" />
            </div> */}
            </div>
          </div>

          <div className="px-4 py-4 bg-black">
            <div className="max-w-screen-xl md:flex md:items-center md:justify-between mx-auto border-b pb-3 border-gray-500">
              <div className="text-sm flex items-center justify-center gap-4 lg:gap-8 text-gray-300">
                {/* <a href="cronbay-tech.com/"> */}
                {/* Powered by Cronbay Technologies Pvt Ltd © 2024 */}
                {/* </a>{" "} */}
                <Link to="/TermsConditions">Terms and Conditions </Link>
                <Link to="/PrivacyPolicy"> Privacy Policy</Link>
              </div>
              <div className="flex mt-4 justify-center md:mt-0 space-x-2 rtl:space-x-reverse">
                <Link
                  to="#"
                  className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <img src={facebook} className="h-8" alt="Facebook" />
                </Link>
                <Link
                  to="#"
                  className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <img src={twitter} className="h-8" alt="Twitter" />
                </Link>
                <Link
                  to="#"
                  className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <img src={instagram} className="h-8" alt="Instagram" />
                </Link>
                <Link
                  to="#"
                  className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <img src={linkedIn} className="h-8" alt="LinkedIn" />
                </Link>
                <Link
                  to="#"
                  className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <img src={youtube} className="h-8" alt="YouTube" />
                </Link>
              </div>
            </div>
            <p className="text-sm text-gray-300 text-center pt-3">
              Powered by Cronbay Technologies Pvt Ltd © 2024 . All Rights
              Reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
