import React, { useState, useEffect } from "react";
import axios from "axios";
import Downloadbar from "./Downloadbar";

const Sidebar = ({ sideindex, isOpen, setIsOpen }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const [category, setCategory] = useState([]);
  const [focusedCategory, setFocusedCategory] = useState(null);

  const API = "https://www.cron-media.com:8080/category.php";

  useEffect(() => {
    axios
      .post(API)
      .then(res => {
        setCategory(res.data.data);
      })
      .catch(err => {
        console.error("Error fetching categories:", err);
      });
  }, []);

  //  console.log(sideindex);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setShowList(true);
      }, 250);
      return () => clearTimeout(timer);
    } else {
      setShowList(false);
    }
  }, [isOpen]);

  // Handle click for the "All" button
  const handleAllClick = () => {
    setFocusedCategory(null);
    sideindex("");
  };

  // Handle click for category buttons
  const handleCategoryClick = categoryId => {
    setFocusedCategory(categoryId);
    sideindex(categoryId);
  };

  return (
    <div
      className={`transition-all duration-300 z-40 ${
        isOpen ? "" : "hidden md:block"
      }`}>
      {/* Sidebar button */}
      <div
        className={`absolute top-0 m-5 h-10 w-10  p-2 bg-gray-600 rounded transition-all duration-300 ${
          isOpen ? " left-60" : "left-0"
        }`}>
        <button onClick={toggleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 "
            viewBox="0 -960 960 960"
            fill="#e8eaed">
            {isOpen ? (
              <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
            ) : (
              <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
            )}
          </svg>
        </button>
      </div>

      {/* Sidebar content */}
      <div
        className={` h-screen sticky overflow-auto shadow-xl bg-[#f8fafc] transition-all duration-300 flex-col justify-between ${
          isOpen ? "w-60" : "w-0"
        }`}>
        <div className={`${showList ? "block" : "hidden"} text-black w-full`}>
          <div className=" flex flex-col mt-2">
            <button
              key="all"
              onClick={handleAllClick}
              className={`p-2 text-start cursor-pointer ${
                focusedCategory === null ? "bg-gray-300" : "hover:bg-gray-300"
              }`}>
              All
            </button>
            {category.map((element, index) => (
              <button
                key={index}
                onClick={() => handleCategoryClick(element.categoryId)}
                className={`p-2 text-start cursor-pointer ${
                  focusedCategory === element.categoryId
                    ? "bg-gray-300"
                    : "hover:bg-gray-300"
                }`}>
                {element.categoryName}
              </button>
            ))}
          </div>
        </div>
        <div className={`${showList ? "block" : "hidden"} w-full`}>
          <Downloadbar />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
