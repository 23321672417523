import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import AdminNavbar from "./AdminNavbar";
import withAdminAuth from "./AdminAuth";

const AdminUsers = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const [users, setUsers] = useState([]);
  const [apiUser, setApiUser] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `https://www.cron-media.com:8080/user.php`
      );
      setUsers(response.data);
      setApiUser(response.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handelSearch = e => {
    let apiData = [...apiUser];
    console.log(e.target.value);
    const result = apiData.filter(element => {
      if (e.target.value === "") {
        return true;
      } else if (
        element.userName?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      } else if (
        element.userEmail?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      } else if (
        element.userRole?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      } else if (
        element.userPlan?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      } else if (
        element.userPricingPlan
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setUsers(result);
  };

  return (
    <>
      <AdminNavbar />
      <div className="container mx-auto p-4">
        <h1 className=" font-mango text-3xl text-center my-4">Users</h1>
        <div className="relative">
          <input
            type="text"
            placeholder="Search users"
            className="p-2 border border-gray-300 rounded mb-4 w-full"
            onChange={e => handelSearch(e)}
          />
        </div>

        <div className="overflow-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 border-r">User ID</th>
                <th className="py-2 px-4 border-r">Username</th>
                <th className="py-2 px-4 border-r">Email</th>
                <th className="py-2 px-4 border-r">Role</th>
                <th className="py-2 px-4 border-r">Plan</th>
                <th className="py-2 px-4  border-r">Pricing Plan</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user?.userId} className="border-b">
                  <td className="py-2 px-4 border-r">{user.userId}</td>
                  <td className="py-2 px-4 border-r">{user.userName}</td>
                  <td className="py-2 px-4 border-r">{user.userEmail}</td>
                  <td className="py-2 px-4 border-r">{user.userRole}</td>
                  <td className="py-2 px-4 border-r">{user.userPlan}</td>
                  <td className="py-2 px-4 border-r">{user.userPricingPlan}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withAdminAuth(AdminUsers);
