import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import withAuth from "../Dashboard/DashboardAuth";
import Header from "../../Header/Header";
import DashboardFooter from "../Footer/DashboardFooter";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";
import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useGoogleLogin } from "@react-oauth/google";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const UserProfile = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const [data, setData] = useState([]);
  // const [googleflag, setGoogleFlag] = useState("");
  const googleflag = "1";
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notification, setNotification] = useState("1");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const email = Cookies.get("email");
  const userID = Cookies.get("userID");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // const name = data?.name ? data.name.split(" ") : [];
  const [photoName, setPhotoName] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const photoInputRef = useRef(null);

  const toggleNotification = () => {
    if (notification === "1") {
      setNotification("0");
    } else {
      setNotification("1");
    }
  };

  const handlePhotoChange = e => {
    let file = e.target.files[0];
    // setPhotoName(file);
    console.log(photoName);
    const reader = new FileReader();
    reader.onload = e => {
      setPhotoPreview(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleAdd = async e => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("userid", userID);
    formData.append("first_name", firstName);
    formData.append("surname", surName);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("phone_number", phoneNumber);
    formData.append("notification", notification);
    formData.append("img", photoName);
    // Show loading toast
    const toastId = toast.loading("Uploading...");

    try {
      const response = await axios.post(
        `https://www.cron-media.com:8080/profilePage.php?userID=${userID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === undefined) {
        console.log(response.data.status);
        toast.success("Uploaded Successfully ✌️", {
          id: toastId, // Update the loading toast with success
        });
        // navigate("/adminTemplates");
      } else {
        console.log(response.data.status);
        toast.error("Upload Unsuccessful 💀", {
          id: toastId, // Update the loading toast with error
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Upload Unsuccessful 💀", {
        id: toastId, // Update the loading toast with error
      });
    }
  };

  // Delete template
  const handleDelete = async e => {
    e.preventDefault();
    try {
      if (window.confirm("Are you sure you want to delete this collection?")) {
        await axios.delete(
          `https://www.cron-media.com:8080/profilePage.php?userID=${userID}`
        );
        navigate("/", toast.success("Deleted Successfully 🗑️"));
        Cookies.remove("email");
        Cookies.remove("userID");
        Cookies.remove("status");
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  useEffect(() => {
    axios
      .post("https://www.cron-media.com:8080/profile.php", {
        email: email,
      })
      .then(output => {
        // console.log(output);
        const data = output.data.data[0];
        console.log(data);
        setData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [email]);

  //password validator
  useEffect(() => {
    if (confirmPassword && newPassword !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }, [newPassword, confirmPassword]);

  const handlePasswordChange = e => {
    const value = e.target.value;
    setNewPassword(value);
  };

  const handleChangePassword = async e => {
    e.preventDefault();

    // Send the email and hashed password to the backend
    try {
      const response = await axios.post(
        "https://www.cron-media.com:8080/loginWithGoogle.php",
        {
          email: email,
          googlesub: currentPassword,
        }
      );
      if (response.data.status === true) {
        try {
          axios.put(
            `https://www.cron-media.com:8080/profilePage.php?userID=${userID}`,
            {
              googlesub: newPassword,
            }
          );
          toast.success("Password Changed Successfully ✌️");
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Password not matched 😣");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // continue with google
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
        );
        const result = userInfo.data;
        Cookies.set(result);
        // setGoogleFlag("1");
        try {
          axios.put(
            `https://www.cron-media.com:8080/registerWithGoogle.php?userID=${userID}`,
            {
              googleflag,
            }
          );
          toast.success("Google Login Successfully ✌️");
          window.location.reload();
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <Header />
      <section className="bg-gray-100 py-10 min-h-screen">
        <div className="max-w-6xl bg-white mx-auto shadow-lg p-5 rounded-lg">
          {/* connect with google */}
          {data.googleflag === "1" ? null : (
            <div className="p-4 border rounded-md flex flex-col gap-4 mb-5">
              <h2 className="font-semibold">CONNECT WITH GOOGLE</h2>
              <button
                onClick={() => googleLogin()}
                className="bg-white text-black border-2 border-gray-300 rounded-lg px-4 py-2 mb-4 flex items-center justify-center w-fit">
                <img
                  src="https://www.google.com/favicon.ico"
                  alt="Google"
                  className="w-8 h-8 mr-2"
                />
                Continue with Google
              </button>
            </div>
          )}

          {/* Account Setting */}
          <div>
            <div className="border rounded-md mb-5">
              <h2 className="font-semibold border-b p-4">ACCOUNT SETTING</h2>
              <form>
                <div className="xmx-auto p-4">
                  <div className="flex flex-col lg:flex-row justify-between w-full">
                    <div className="flex flex-col justify-center items-center">
                      <div className="relative w-28 h-28 rounded-full overflow-hidden">
                        {/* <img
                          src="https://via.placeholder.com/150"
                          alt="Profile"
                          className="object-cover w-full h-full"
                        /> */}
                        <input
                          type="file"
                          name="img"
                          className="hidden"
                          ref={photoInputRef}
                          onChange={e => {
                            setPhotoName(e.target.files[0]);
                            handlePhotoChange(e);
                          }}
                        />

                        <div className="text-center">
                          {data.Image !== null &&
                          data.Image !== undefined &&
                          data.Image !== "" ? (
                            <img
                              src={`https://www.cron-media.com:8080/userImages/${data.Image}`}
                              alt="Profile"
                              className="w-40 h-40 rounded-full"
                            />
                          ) : !photoPreview ? (
                            <div className="mt-2">
                              <svg
                                width="136"
                                height="136"
                                viewBox="0 0 136 136"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-24 h-24 m-auto">
                                <g opacity="0.2">
                                  <rect
                                    x="2.76335"
                                    y="2.8001"
                                    width="130.24"
                                    height="130.24"
                                    rx="65.12"
                                    stroke="black"
                                    strokeWidth="4.4"
                                  />
                                  <circle
                                    cx="67.8833"
                                    cy="67.9202"
                                    r="28.9502"
                                    stroke="black"
                                    strokeWidth="4.57959"
                                  />
                                  <mask
                                    id="path-3-inside-1_568_2951"
                                    fill="white">
                                    <path d="M84.383 67.5903C84.383 72.0538 82.6099 76.3346 79.4536 79.4909C76.2974 82.6471 72.0166 84.4203 67.553 84.4203C63.0894 84.4203 58.8087 82.6471 55.6524 79.4909C52.4962 76.3346 50.723 72.0539 50.723 67.5903H56.1296C56.1296 70.6199 57.3332 73.5255 59.4755 75.6678C61.6178 77.8101 64.5234 79.0136 67.553 79.0136C70.5827 79.0136 73.4883 77.8101 75.6306 75.6678C77.7729 73.5255 78.9764 70.6199 78.9764 67.5903H84.383Z" />
                                  </mask>
                                  <path
                                    d="M84.383 67.5903C84.383 72.0538 82.6099 76.3346 79.4536 79.4909C76.2974 82.6471 72.0166 84.4203 67.553 84.4203C63.0894 84.4203 58.8087 82.6471 55.6524 79.4909C52.4962 76.3346 50.723 72.0539 50.723 67.5903H56.1296C56.1296 70.6199 57.3332 73.5255 59.4755 75.6678C61.6178 77.8101 64.5234 79.0136 67.553 79.0136C70.5827 79.0136 73.4883 77.8101 75.6306 75.6678C77.7729 73.5255 78.9764 70.6199 78.9764 67.5903H84.383Z"
                                    stroke="black"
                                    strokeWidth="9.15918"
                                    mask="url(#path-3-inside-1_568_2951)"
                                  />
                                </g>
                              </svg>
                            </div>
                          ) : (
                            <div className="mt-2">
                              <span
                                className="block w-40 h-40 rounded-full m-auto shadow"
                                style={{
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center center",
                                  backgroundImage: `url('${photoPreview}')`,
                                }}></span>
                            </div>
                          )}
                          <button
                            type="button"
                            className="absolute inset-0 bg-black bg-opacity-50 text-white flex items-center justify-center"
                            onClick={() => photoInputRef.current.click()}>
                            Tap to Change
                          </button>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label className="block text-center text-sm font-medium text-gray-700">
                          Username
                        </label>
                        <div className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">
                          {data.name}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <input
                          type="text"
                          value={data.firstName}
                          onChange={e => setFirstName(e.target.value)}
                          placeholder="John"
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Surame
                        </label>
                        <input
                          type="text"
                          value={data.surName}
                          onChange={e => setSurName(e.target.value)}
                          placeholder="Doe"
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          value={email}
                          disabled
                          //   onChange={e => setEmail(e.target.value)}
                          placeholder="email@.com"
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          value={data.phoneNo}
                          onChange={e => setPhoneNumber(e.target.value)}
                          placeholder="999-587-246-8"
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Country/Region
                        </label>
                        <CountryDropdown
                          defaultOptionLabel="Select country"
                          value={data.country}
                          className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm"
                          onChange={val => setCountry(val)}
                        />
                        {/* //   value={country}
                          //   onChange={e => setCountry(e.target.value)}
                          className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm">
                          <option>Bangladesh</option> */}
                        {/* Add more options as needed */}
                      </div>
                      {/* <div className="flex gap-2"> */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          State
                        </label>
                        <RegionDropdown
                          blankOptionLabel="No country selected"
                          defaultOptionLabel="Select State"
                          country={country}
                          value={data.state}
                          onChange={val => setState(val)}
                          className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm"
                        />
                      </div>
                      {/* <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Zip Code
                          </label>
                          <input
                            type="text"
                            //   value={zipCode}
                            //   onChange={e => setZipCode(e.target.value)}
                            placeholder="1207"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                          />
                        </div> */}
                      {/* </div> */}
                      <div className=" hidden md:block">
                        <button
                          onClick={handleAdd}
                          className="w-full py-2 px-4 bg-black text-white font-medium rounded-md">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 md:hidden">
                    <button
                      onClick={handleAdd}
                      className="w-full py-2 px-4 bg-black text-white font-medium rounded-md">
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {data.googleflag === "1" ? null : (
              <div className="border rounded-md mb-5">
                <h2 className="font-semibold border-b p-4">CHANGE PASSWORD</h2>
                <div className="p-4">
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Current Password
                    </label>
                    <div className="relative">
                      <input
                        type={showCurrentPassword ? "text" : "password"}
                        value={currentPassword}
                        onChange={e => setCurrentPassword(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none">
                        <span className="material-icons text-gray-500">
                          {showCurrentPassword ? (
                            <FaRegEye />
                          ) : (
                            <FaRegEyeSlash />
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={handlePasswordChange}
                        placeholder="8+ characters"
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      />
                      <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none">
                        <span className="material-icons text-gray-500">
                          {showNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none">
                        <span className="material-icons text-gray-500">
                          {showConfirmPassword ? (
                            <FaRegEye />
                          ) : (
                            <FaRegEyeSlash />
                          )}
                        </span>
                      </button>
                    </div>
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                  </div>
                </div>

                <button
                  onClick={handleChangePassword}
                  className="w-fit m-4 py-2 px-4 bg-black text-white font-medium rounded-md">
                  Change Password
                </button>
              </div>
            )}

            <div className="border rounded-md">
              <p className="font-semibold border-b p-4">NOTIFICATION</p>
              <div className="flex justify-between p-4">
                <p>
                  Receive newsletters, promotions and news from Freepik Company
                </p>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={notification === "1" ? true : false}
                    className="sr-only peer"
                    onChange={toggleNotification}
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
                </label>
              </div>
            </div>

            {/* <div className="mt-5">
              <p className="px-4">
                Use the toggle component to switch between a binary state of
                true or false using a single click available in multiple sizes,
                variants, and colors · The toggle component can be used to
                receive a simple “yes” or “no” type of answer from the user by
                choosing a single option from.
              </p>
            </div> */}

            <button
              className="border mx-4 border-red-600 text-red-600 bg-gray-200 rounded-md font-medium p-2 mt-6 mb-10"
              onClick={handleDelete}>
              Close my account
            </button>
          </div>
        </div>
      </section>

      <DashboardFooter />
    </>
  );
};

export default withAuth(UserProfile);
