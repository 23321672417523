import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const withAdminAuth = WrappedComponent => {
  return props => {
    const status = Cookies.get("admin");
    if (status !== "active") {
      return <Navigate to="/adminLogin" />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withAdminAuth;
