import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
import Plans from "../../PricingPage/Plans";

function RegisterStep3({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const location = useLocation();
  const data = location.state;
  const img = data.data.img;
  console.log(img);

  const role = data.role;
  const plans = data.data.plans;
  const email = data.data.data.email;
  const googlesub = data.data.data.googlesub;
  const googleflag = data.data.data.googleflag;
  var name = data.data.data.name;

  if (name === undefined && name === null && name === "") {
    name = data.data.name;
  } else {
    name = data.data.data.name;
  }

  Cookies.set("status", "active", { expires: 1 });
  Cookies.set("email", email, { expires: 1 });

  return (
    <div className=" flex flex-col items-center">
      <nav className=" w-screen sticky bg-white top-0 px-4 py-2.5 z-10">
        <div className="max-w-screen-xl mx-auto">
          <Link to="/" className="flex">
            <img src={logo} className="h-6 mr-6 sm:h-9" alt="CronMedia Logo" />
          </Link>
        </div>
      </nav>
      <section className="max-w-screen-xl mx-auto bg-white flex flex-col items-center justify-center my-12">
        <h2 className="text-4xl font-extrabold text-black sm:text-5xl font-mango">
          Start Trial
        </h2>
        <div>
          <Plans
            role={role}
            plans={plans}
            email={email}
            img={img}
            name={name}
            googlesub={googlesub}
            googleflag={googleflag}
          />
        </div>
      </section>
    </div>
  );
}

export default RegisterStep3;
