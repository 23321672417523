import { useState } from "react";
import countries from "./countries.json";
import axios from "axios";
import toast from "react-hot-toast";
import { IoCall } from "react-icons/io5";
// import { IoIosMail } from "react-icons/io";
import { IoHome } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";

function Contact({ setShowFooter }) {
  setShowFooter = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

const handleChange = e => {
  console.log("Selected value:", e.target.value); // Log the selected value
  setSelectedCountry(e.target.value); // Directly use the value
};

  const handleSubmit = async e => {
    e.preventDefault();

    const phoneNumber = selectedCountry + phone;    
    const toastId = toast.loading("Subscribing");

    // Send the email, name, phoneNumber, and message to the backend
    axios
      .post("https://www.cron-media.com:8080/contactUs.php", {
        email,
        name,
        phoneNumber,
        message,
      })
      .then(output => {
        console.log(output);
        // Reset the form fields after successful submission
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setSelectedCountry("+91");
        toast.success("Form Submitted😊", {
          id: toastId,
        });
      })
      .catch(error => {
        console.log(error);
        toast.error("Error 💀", {
          id: toastId,
        });
      });
  };

  return (
    <div className="mx-auto w-full max-w-screen-xl flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col lg:flex-row justify-center w-full md:py-12 my-10 lg:mx-32 gap-11">
        <div className="text-left w-full lg:w-1/2 ">
          <div className="mb-4">
            <div className="text-xl lg:text-2xl font-bold mb-2 flex items-center gap-3">
              <IoCall color="#212dad" />
              <h1 className="text-transparent bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] bg-clip-text">
                Call Us
              </h1>
            </div>
            <p className="text-gray-700 mb-6">+91 8904366659</p>
          </div>

          {/* <div>
            <h1 className="text-xl lg:text-2xl font-bold mb-2 flex items-center gap-3">
              <IoIosMail /> Mail Us
            </h1>
            <a
              href="mailto: contact@cronbay-tech.com"
              className="text-gray-700 mb-6">
              contact@cronbay-tech.com
            </a>
          </div> */}

          <div className="mb-4">
            <div className="text-xl  lg:text-2xl font-bold mb-2 flex items-center gap-3">
              <IoHome color="#212dad" />
              <h1 className="text-transparent bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] bg-clip-text">
                {" "}
                Address
              </h1>
            </div>
            <ul className="list-none">
              <li className="text-gray-700 mb-6 flex items-center gap-3 align-center">
                <IoLocationSharp
                  size={20}
                  style={{ minWidth: "20px", minHeight: "20px" }}
                />
                105, Brigade IRV Centre, Nallurhalli Rd, Whitefield, Bengaluru,
                Karnataka 560066
              </li>
              <li className="text-gray-700 mb-6 flex items-center gap-3">
                <IoLocationSharp
                  size={20}
                  style={{ minWidth: "20px", minHeight: "20px" }}
                />
                001 - 39332, IFZA Business Park, Dubai Silicon Oasis, DDP,
                Building A1, Dubai, United Arab Emirates
              </li>
              <li className="text-gray-700 mb-6 flex items-center gap-3 align-center">
                <IoLocationSharp
                  size={20}
                  style={{ minWidth: "20px", minHeight: "20px" }}
                />
                10 Anson Road, #13-09 International Plaza, Singapore 079903
              </li>
            </ul>
          </div>
          <div className="">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62208.430523445255!2d77.730643!3d12.97013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13734e00df9f%3A0x6f7f75e99fbcf481!2sCronbay%20Technologies!5e0!3m2!1sen!2sin!4v1724233069022!5m2!1sen!2sin"
              // width="550"
              height="200"
              className=" w-full rounded-lg shadow-md"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        <div className="bg-white rounded-xl p-2 sm:p-8 border-2 w-full lg:w-1/2">
          <h2 className="text-xl lg:text-2xl font-bold mb-4 text-transparent bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] bg-clip-text">Contact Us</h2>
          <form id="contact" onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                className="shadow-md appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                id="name"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="mb-4">
              <input
                className="shadow-md appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                id="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>

            <div className="mb-4">
              <div className="flex flex-col gap-2 sm:gap-4 sm:flex-row">
                <select
                  className="shadow-md inline-flex items-center px-3 py-2 rounded-lg border border-gray-300 bg-gray-100 text-gray-500 text-sm mb-2 sm:mb-0"
                  value={selectedCountry}
                  onChange={handleChange}>
                  {countries.map(country => (
                    <option key={country.dial_code} value={country.dial_code}>
                      {country.flag} {country.dial_code}
                    </option>
                  ))}
                </select>
                <input
                  className="shadow-md appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  placeholder="123-456-7890"
                  pattern="[0-9]{10}"
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <textarea
                className="shadow-md appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                id="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="How Can I help you?"
                rows="6"
              />
            </div>
            <div className="mb-4">
              <button
                className="w-full shadow-md bg-gradient-to-r from-[#7b51fe] to-[#212cad] hover:from-[#6146e5] hover:to-[#3a36c3] text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
                type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
