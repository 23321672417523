const RefundPolicy = ({ showFooter, setShowFooter }) => {
  setShowFooter(true);

  return (
    <div className="bg-slate-200 py-6 px-10">
      <main class="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-6xl">
        <section class="mb-8 mx-4">
          <h2 class="text-3xl font-semibold mb-6 border-b-2 border-gray-200 pb-2">
            Refund Policy
          </h2>

          <p class="mb-6 text-lg leading-relaxed">
            At Cron-Media.com, we strive to ensure the satisfaction of our users
            and businesses by providing high-quality content download services.
            However, we understand that there may be instances where a refund is
            necessary. This refund policy outlines the conditions under which
            refunds are issued.
          </p>

          <h2 class="text-2xl font-semibold mb-4">
            1. Eligibility for Refunds
          </h2>
          <p class="mb-6 underline underline-offset-4 text-lg leading-relaxed">
            Refunds may be granted under the following conditions:
          </p>
          <ul className="list-disc">
            <li className="mb-2">
              Technical Issues: If you encounter a technical issue that prevents
              you from accessing or downloading the content purchased, and our
              support team is unable to resolve the problem within a reasonable
              time, you may be eligible for a refund.
            </li>
            <li className="mb-2">
              Duplicate Purchases: If you accidentally purchase the same content
              more than once, we will issue a refund for the duplicate
              transaction.
            </li>
            <li className="mb-2">
              Unauthorized Transactions: If you believe that a purchase was made
              without your authorization, please contact us immediately. After
              verifying the unauthorized transaction, we will issue a refund.
            </li>
          </ul>

          <h2 class="text-2xl font-semibold mb-4">
            2. Non-Refundable Situations
          </h2>
          <p class="mb-6 underline underline-offset-4 text-lg leading-relaxed">
            Refunds will not be issued in the following situations:
          </p>
          <ul className="list-disc">
            <li className="mb-2">
              Downloaded Content: If the content has been successfully
              downloaded, it is considered consumed, and no refunds will be
              provided.
            </li>
            <li className="mb-2">
              Change of Mind: Refunds are not available for purchases made where
              the user simply changes their mind after the purchase.
            </li>
            <li className="mb-2">
              Partial Refunds: We do not provide partial refunds for content
              that has been partially downloaded or accessed.
            </li>
          </ul>

          <h2 class="text-2xl font-semibold mb-4">3. Refund Request Process</h2>
          <p class="mb-6 underline underline-offset-4 text-lg leading-relaxed">
            To request a refund, please follow these steps:
          </p>
          <ul className="list-disc">
            <li className="mb-2">
              Contact Customer Support: Submit a refund request to our customer
              support team at{" "}
              <a href="mailto:support@cron-media.com" class=" hover:underline">
                support@cron-media.com
              </a>{" "}
              within 7 days of the purchase date.
            </li>
            <li className="mb-2">
              Provide Details: Include your order number, the email address
              associated with the purchase, and a detailed explanation of the
              issue.
            </li>
            <li className="mb-2">
              Review Process: Our team will review your request and respond
              within 3-5 business days. If approved, the refund will be
              processed using the original payment method.
            </li>
          </ul>

          <h2 class="text-2xl font-semibold mb-4">4. Processing Time</h2>
          <p class="mb-6 text-lg leading-relaxed">
            Refunds typically take 5-10 business days to appear in your account,
            depending on your bank or payment provider.
          </p>

          <h2 class="text-2xl font-semibold mb-4">5. Changes to This Policy</h2>
          <p class="mb-6 text-lg leading-relaxed">
            Cron-Media.com reserves the right to update or modify this refund
            policy at any time without prior notice. Any changes will be posted
            on this page, and the date of the last update will be indicated.
          </p>
        </section>
      </main>
    </div>
  );
};

export default RefundPolicy;
