import { useState } from "react";

function MainHighlights() {
  const [hideSection, setHideSection] = useState(false);
  return (
    <>
      <div
        className={`w-full mt-12 mx-auto px-4 md:px-8 ${
          hideSection ? "hidden" : "block"
        }`}>
        <div className="bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] p-4 rounded-xl">
          <div className="flex justify-between">
            <h2 className="text-white text-xl font-medium font-mango">
              Main Highlights
            </h2>
            <button
              className="text-white text-lg"
              onClick={() => setHideSection(!hideSection)}>
              x
            </button>
          </div>

          <div className="overflow-x-auto mt-4">
            <div className="flex space-x-4">
              <div className="bg-white p-4 rounded-lg flex-shrink-0 w-64">
                <div className="flex justify-between">
                  <p className="text-sm font-semibold font-mango">
                    Graphic Design Templates
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 320 512">
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </p>
                </div>
                <p className="text-xs mt-4">
                  Kickstart your journey with print graphic design templates made
                  by creative minds. With product mockups, UX/UI kits,
                  infographics, logos, scene generators, and more, we've got
                  everything covered!
                </p>
              </div>

              <div className="bg-white p-4 rounded-lg flex-shrink-0 w-64">
                <div className="flex justify-between">
                  <p className="text-sm font-semibold font-mango">
                    Social Media Templates
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 320 512">
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </p>
                </div>
                <p className="text-xs mt-4">
                  Create thumb-stopping social media posts with templates for
                  Facebook, Instagram, YouTube, Twitter, LinkedIn, and more. Start
                  standing out and make your branding and marketing a breeze!
                </p>
              </div>

              <div className="bg-white p-4 rounded-lg flex-shrink-0 w-64">
                <div className="flex justify-between">
                  <p className="text-sm font-semibold font-mango">
                    Digital Marketing Template
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 320 512">
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </p>
                </div>
                <p className="text-xs mt-4">
                  Gain access to quick, cost-effective, and impactful templates
                  for your upcoming digital marketing campaigns. Explore our
                  collection of ready-to-use templates, checklists, and sample
                  strategies and amplify your brand success without a wait.
                </p>
              </div>

              <div className="bg-white p-4 rounded-lg flex-shrink-0 w-64">
                <div className="flex justify-between">
                  <p className="text-sm font-semibold font-mango">
                    Icon Template
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 320 512">
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </p>
                </div>
                <p className="text-xs mt-4">
                  Easily find any icon that suits your brand's voice, and
                  customize it to fit your style with our Color Palette. Enjoy
                  pixel-perfect icons available in both PNG and vector formats for
                  all your design needs.
                </p>
              </div>

              <div className="bg-white p-4 rounded-lg flex-shrink-0 w-64">
                <div className="flex justify-between">
                  <p className="text-sm font-semibold font-mango">
                    Video Template
                  </p>
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 320 512">
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </p>
                </div>
                <p className="text-xs mt-4">
                  Discover premium templates for all your video needs, whether for
                  social media, YouTube, corporate presentations, or even meme
                  videos. Our templates are easy to use and fully customizable
                  with a simple drag-and-drop interface, ensuring your videos pop
                  up instantly!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainHighlights;
