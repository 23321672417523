import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
// import icon from "../../asstes/Images/icon.png";

function Registerindex({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [plans, setPlans] = useState("Just Curious");
  const [photoName, setPhotoName] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [username, setUsername] = useState("");
  const photoInputRef = useRef(null);

  const handlePhotoChange = e => {
    let file = e.target.files[0];
  
    // setPhotoName(file); 
    console.log(photoName);
    const reader = new FileReader();
    reader.onload = e => {
      setPhotoPreview(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const submitFunction = async e => {
    e.preventDefault(); // Prevent default form submission behavior
    // setPlans(plans);

    navigate("/registerstep2", {
      state: { data, name:username, img:photoName, plans },
    });
  };

  console.log(photoName);
  return (
    <div>
      <section className="max-w-screen-xl h-screen flex flex-col items-center justify-center bg-white mx-auto my-auto px-4 md:px-8">
        <nav className="w-screen fixed bg-white top-0 px-4 py-2.5">
          <div className="max-w-screen-xl mx-auto">
            <Link to="/" className="flex">
              <img
                src={logo}
                className="h-6 mr-6 sm:h-9"
                alt="CronMedia Logo"
              />
            </Link>
          </div>
        </nav>

        <form
          className="w-full max-w-2xl bg-white p-8"
          encType="multipart/form-data"
          onSubmit={submitFunction}>
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="flex flex-col items-center justify-center p-4">
                <div className="w-full sm:w-1/2 lg:w-1/3">
                  <input
                    type="file"
                    name="img"
                    className="hidden"
                    ref={photoInputRef}
                    onChange={e => {
                      setPhotoName(e.target.files[0]);
                      handlePhotoChange(e);
                    }}
                  />

                  <div className="text-center">
                    {!photoPreview ? (
                      <div className="mt-2">
                        <svg
                          width="136"
                          height="136"
                          viewBox="0 0 136 136"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-24 h-24 m-auto">
                          <g opacity="0.2">
                            <rect
                              x="2.76335"
                              y="2.8001"
                              width="130.24"
                              height="130.24"
                              rx="65.12"
                              stroke="black"
                              strokeWidth="4.4"
                            />
                            <circle
                              cx="67.8833"
                              cy="67.9202"
                              r="28.9502"
                              stroke="black"
                              strokeWidth="4.57959"
                            />
                            <mask id="path-3-inside-1_568_2951" fill="white">
                              <path d="M84.383 67.5903C84.383 72.0538 82.6099 76.3346 79.4536 79.4909C76.2974 82.6471 72.0166 84.4203 67.553 84.4203C63.0894 84.4203 58.8087 82.6471 55.6524 79.4909C52.4962 76.3346 50.723 72.0539 50.723 67.5903H56.1296C56.1296 70.6199 57.3332 73.5255 59.4755 75.6678C61.6178 77.8101 64.5234 79.0136 67.553 79.0136C70.5827 79.0136 73.4883 77.8101 75.6306 75.6678C77.7729 73.5255 78.9764 70.6199 78.9764 67.5903H84.383Z" />
                            </mask>
                            <path
                              d="M84.383 67.5903C84.383 72.0538 82.6099 76.3346 79.4536 79.4909C76.2974 82.6471 72.0166 84.4203 67.553 84.4203C63.0894 84.4203 58.8087 82.6471 55.6524 79.4909C52.4962 76.3346 50.723 72.0539 50.723 67.5903H56.1296C56.1296 70.6199 57.3332 73.5255 59.4755 75.6678C61.6178 77.8101 64.5234 79.0136 67.553 79.0136C70.5827 79.0136 73.4883 77.8101 75.6306 75.6678C77.7729 73.5255 78.9764 70.6199 78.9764 67.5903H84.383Z"
                              stroke="black"
                              strokeWidth="9.15918"
                              mask="url(#path-3-inside-1_568_2951)"
                            />
                          </g>
                        </svg>
                      </div>
                    ) : (
                      <div className="mt-2">
                        <span
                          className="block w-40 h-40 rounded-full m-auto shadow"
                          style={{
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            backgroundImage: `url('${photoPreview}')`,
                          }}></span>
                      </div>
                    )}
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 mt-2 ml-3"
                      onClick={() => photoInputRef.current.click()}>
                      Select New Photo
                    </button>
                  </div>
                </div>
                <input
                  type="text"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  className="w-full mt-4 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-400 focus:border-blue-400"
                  placeholder="Username"
                />
              </div>

              <h2 className="py-4 text-2xl font-bold text-black font-mango">
                Which exciting plans do you have in mind for CronMedia?
              </h2>
              <div className="flex flex-wrap gap-4 py-4 items-center text-sm">
                <button
                  type="button"
                  value="To manage my creatives"
                  onClick={e => {
                    setPlans(e.target.textContent);
                  }}
                  className={`rounded-full px-3 py-2 border ${
                    plans === "To manage my creatives"
                      ? "bg-[#4e22ff] text-white border-white"
                      : "bg-white text-black border-black"
                  } cursor-pointer transform hover:scale-105 transition duration-300`}>
                  To manage my creatives
                </button>
                <button
                  type="button"
                  value="For designing"
                  onClick={e => {
                    setPlans(e.target.textContent);
                  }}
                  className={`rounded-full px-3 py-2 border ${
                    plans === "For designing"
                      ? "bg-[#4e22ff] text-white border-white"
                      : "bg-white text-black border-black"
                  } cursor-pointer transform hover:scale-105 transition duration-300`}>
                  For designing
                </button>
                <button
                  type="button"
                  value="Not Sure yet"
                  onClick={e => setPlans(e.target.textContent)}
                  className={`rounded-full px-3 py-2 border ${
                    plans === "Not Sure yet"
                      ? "bg-[#4e22ff] text-white border-white"
                      : "bg-white text-black border-black"
                  } cursor-pointer transform hover:scale-105 transition duration-300`}>
                  Not Sure yet
                </button>
                <button
                  type="button"
                  value="Just Curious"
                  onClick={e => setPlans(e.target.textContent)}
                  className={`rounded-full px-3 py-2 border ${
                    plans === "Just Curious"
                      ? "bg-[#4e22ff] text-white border-white"
                      : "bg-white text-black border-black"
                  } cursor-pointer transform hover:scale-105 transition duration-300`}>
                  Just Curious
                </button>
              </div>
            </div>
            <div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 my-4 ">
                <div className="w-[30%] bg-gradient-to-r from-[#1c9cff] to-[#4e22ff] h-1.5 rounded-full "></div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-gradient-to-r font-semibold rounded-full from-[#4e22ff] to-[#1c9cff] text-white px-8 py-1 transform hover:scale-105 transition duration-300">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default Registerindex;
