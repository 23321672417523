import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import Cookies from "js-cookie";
import img1 from "../asstes/Images/1.png";
import TestimonialCard from "./Testimonials";
import CategoryCards from "./CategoryCards";
import Faq from "./Faq";
import Clients from "../Clietns/Clients.js";
import arrow from "../asstes/Images/Arrow4.png";
import HeroBanner from "./Herobanner";
import Blogs from "./Blogs";
import Cards from "./Cards";
// import Collection from "./Collection.js";

function Homeindex({ showFooter, setShowFooter}) {
  setShowFooter(true);

  const [status, setStatus] = useState("");

  useEffect(() => {
    const cookieStatus = Cookies.get("status");
    setStatus(cookieStatus);
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      <HeroBanner />
      <div className="mx-auto w-full pt-8 block">
        <div
          className="leading-relaxed  mt-12 px-5 xl:px-0 max-w-screen-xl mx-auto"
          id="temp">
          <div className="text-center my-7">
            <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
              TEMPLATES
            </span>
            <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3">
              Find Your Customizable Template!
            </h1>
            <p className="text-gray-600 mx-auto text-lg font-clash my-3">
              Creatives For Impactful Promotions, Product Highlights, Client
              Diaries, & Buzz-Worthy News!
            </p>
          </div>
          <div className="flex justify-end">
            <Link
              to={status === "active" ? "/dashboard" : "/template"}
              className="bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] px-2 text-sm py-1 rounded-full text-white">
              See All
            </Link>
          </div>
          <Cards />
        </div>

        {/* <div className="leading-relaxed mt-12 px-5 xl:px-0 max-w-screen-xl mx-auto">
          <div className="text-center my-7">
            <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
              COLLECTIONS
            </span>
            <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3">
              Browse By Collection
            </h1>
            <p className="text-gray-600 mx-auto text-lg font-clash my-3">
              Templates For Promotions, Product Showcases, Customer
              Testimonials, And Announcement.
            </p>
          </div>
          <div className="flex justify-end">
            <Link
              to={status === "active" ? "/dashboard" : "/template"}
              className="bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] px-2 text-sm py-1 rounded-full text-white">
              See All
            </Link>
          </div>
          <Collection />
        </div> */}
        <TestimonialCard />

        <div className="leading-relaxed mt-12 px-5 xl:px-0 max-w-screen-xl mx-auto">
          <div className="text-center my-7">
            <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
              CLIENT
            </span>
            <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3">
              Our Trusted Clients
            </h1>
          </div>
          <Clients />
        </div>

        <div className=" bg-[#f6fcff] hidden md:block">
          <div className="leading-relaxed mt-12 px-5 xl:px-0 max-w-screen-xl mx-auto">
            <div className="text-center my-7">
              <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
                DOCUMENT
              </span>
              <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3">
                CUSTOMIZE YOUR STYLE
              </h1>
            </div>
            <div className="flex justify-center items-center">
              <img src={img1} alt="img1" />
            </div>
          </div>
        </div>

        <CategoryCards />
          <Faq />

        <div className="sr-only sm:not-sr-only">
          <div className="fixed  bottom-16 z-10 p-5 right-8  ">
            <Link to="/login" className=" main group">
              <p className="rounded-full shadow-[0px_0px_20px_10px_rgba(255,255,255,0.3)]">
                <img
                  className=" group-hover:-translate-y-2 group-hover:translate-x-2 duration-500"
                  src={arrow}
                  alt="Arrow"
                />
              </p>

              <svg
                id="rotatingText"
                fill="#4e22ff"
                viewBox="0 0 200 200"
                width="150"
                height="150">
                <defs>
                  <path
                    id="circle"
                    d="M 100, 100
                  m -75, 0
                  a 75, 75 0 1, 0 150, 0
                  a 75, 75 0 1, 0 -150, 0
                  "></path>
                </defs>
                <text width="400">
                  <textPath
                    alignmentBaseline="top"
                    href="#circle"
                    className="text">
                    Get started Get Started Get Started
                  </textPath>
                </text>
              </svg>
            </Link>
          </div>
        </div>

        <Blogs />
      </div>
    </>
  );
}

export default Homeindex;
