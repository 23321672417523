import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "react-tooltip/dist/react-tooltip.css";
import App from './App';

import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="247836881365-485k43bvbn6r0d2gdqggte1o8ghua16n.apps.googleusercontent.com">
    <Router>
      <App />
    </Router>
  </GoogleOAuthProvider>
);

