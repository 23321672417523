import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCheckCircle } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { Link } from "react-router-dom";

const DigitalAssets = ({ id }) => {
  const [data, setData] = useState({});
  const [carouselData, setCarouselData] = useState([]);
  const [collection, setCollection] = useState([]);
  const userID = Cookies.get("userID");

  useEffect(() => {
    axios
      .get(`https://www.cron-media.com:8080/adminTemplates.php?id=${id}`)
      .then(output => {
        setData(output.data);
        setCarouselData(output.data.Large_preview || []);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id]);

  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: carouselData.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: (
      <button type="button" className="slick-next">
        Next
      </button>
    ),
    prevArrow: (
      <button type="button" className="slick-prev">
        Previous
      </button>
    ),
  };

  // Fetch user's collection
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const response = await axios.get(
          `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}`
        );
        setCollection(response.data.templateCollection || []);
      } catch (error) {
        console.error("Error fetching template collection:", error);
      }
    };

    fetchCollection();
  }, [userID]);

  const addTemplate = id => {
    try {
      axios.post(
        `https://www.cron-media.com:8080/addTemplateCollection.php?userID=${userID}`,
        {
          userID,
          newTemplates: id,
        }
      );
      setCollection(prevCollection => [...prevCollection, id]);
    } catch (error) {
      console.error("Error adding template:", error);
    }
  };

  const addDownload = id => {
    try {
      axios.post(
        `https://www.cron-media.com:8080/Download.php?userID=${userID}`,
        {
          userID,
          newDownload: id,
        }
      );
      setCollection(prevCollection => [...prevCollection, id]);
    } catch (error) {
      console.error("Error adding template:", error);
    }
  };

  
  const handleClick = id => {
    addTemplate(id);
  };

  return (
    <div className="p-4 lg:p-6">
      <header className="text-center flex flex-col lg:flex-row justify-between items-center mb-6">
        <div className="flex flex-col items-start mb-6 lg:mb-0 w-full lg:w-[70%]">
          <div className="flex justify-between">
            <h1 className="text-xl text-start lg:text-2xl font-bold mb-4">
              Browse Thousands Of Digital Assets
            </h1>
          </div>
          <nav className="text-gray-600 text-xs">
            <a href="all" className="mr-2 hover:text-gray-800">
              All Items
            </a>{" "}
            /
            <a href="temp" className="ml-2 hover:text-gray-800">
              Graphic Template
            </a>{" "}
            /
            <a href="social" className="ml-2 hover:text-gray-800">
              Social Media
            </a>
          </nav>
        </div>
        {Cookies.get("status") === "active" ? (
          <button
            onClick={() => handleClick(id)}
            className="h-fit bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 w-fit flex gap-3">
            Collections
            <span>
              {collection.includes(id) ? (
                <FaCheckCircle size={24} color="white" />
              ) : (
                <FaCirclePlus size={24} color="white" />
              )}
            </span>
          </button>
        ) : (
          <Link to="/login">
            <button className="h-fit bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 w-fit flex gap-3">
              Collections
              <span>
                <FaCirclePlus size={24} color="white" />
              </span>
            </button>
          </Link>
        )}
      </header>

      <div className="mt-8 relative">
        <Slider {...settings}>
          {carouselData.map((image, index) => (
            <div key={index} className="w-full">
              <img
                src={`https://www.cron-media.com:8080/Templates/Images/${image}`}
                alt={`Slide ${index}`}
                loading="lazy"
                className="w-full h-56 md:h-96 object-cover rounded-lg"
              />
            </div>
          ))}
        </Slider>
      </div>

      <section className="mt-8 flex flex-col lg:flex-row justify-between items-center">
        <div className="items-start mb-6 lg:mb-0 w-full lg:w-[70%]">
          <h2 className="text-lg lg:text-xl text-start font-semibold">
            {data.Template_Name}
          </h2>
          <p className="text-gray-700 text-start text-sm mt-2">
            {data.Template_Desc}
          </p>
          <p className="text-gray-700 text-start font-semibold mt-2">
            {data.Assets} Assets
          </p>
          <div className="flex flex-wrap space-x-2 my-2">
            {data.Tags?.map((tag, index) => (
              <span
                key={index}
                className="text-xs text-nowrap border border-gray-300 text-black px-2 py-1 my-1 rounded-full h-fit">
                {tag}
              </span>
            ))}
          </div>
        </div>
        <div>
          {Cookies.get("status") === "active" ? (
            <a
              href={`https://www.cron-media.com:8080/Templates/Zip/${data.Template_File}`}
              download
              onClick={() => addDownload(id)}>
              <button className="mt-4 bg-black text-white px-4 py-1 rounded-lg hover:bg-gray-800 w-fit h-fit">
                + Download
              </button>
            </a>
          ) : (
            <Link to="/login">
              <button className="mt-4 bg-black text-white px-4 py-1 rounded-lg hover:bg-gray-800 w-fit h-fit">
                + Download
              </button>
            </Link>
          )}
        </div>
      </section>
    </div>
  );
};

export default DigitalAssets;
