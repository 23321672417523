import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import axios from "axios";
import Cookies from "js-cookie";
import banner from "../asstes/Video/CronMediaWebbanner.webm";
// import phnBanner from "../asstes/Video/CronMediaWebbanner Phone.mp4";
import arrow from "../asstes/Images/Arrow4.png";
import { HiMiniArrowLongDown } from "react-icons/hi2";

const HeroBanner = () => {
  const [totalcategory, setTotalCategory] = useState([]);
  const [sideindex, setSideIndex] = useState();
  const [search, setSearch] = useState();

  const navigate = useNavigate();

  const handleChange = event => {
    const selectedValue = event.target.value;
    setSideIndex(selectedValue);
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSearchSubmit = e => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      Cookies.set("searchterm", search, { expires: 1 });
      Cookies.set("categoryIndex", sideindex, { expires: 1 });
      if (Cookies.get("status") === "active") {
        navigate("/dashboard");
      }
      else {
        navigate("/template");
      }
    }
  };

  // category
  useEffect(() => {
    axios.get(`https://www.cron-media.com:8080/category.php`).then(response => {
      const catdata = response.data;
      console.table(catdata.data);
      // console.dir(catdata.data);
      // console.log(catdata.data);
      setTotalCategory(catdata.data);
    });
  }, []);

  const handleScroll = e => {
    e.preventDefault();
    document.querySelector("#temp").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className="relative ">
      <div className="relative w-full h-screen overflow-hidden">
        <video
          className="absolute inset-0 w-full h-full object-cover object-bottom"
          loading="lazy"
          autoPlay
          loop
          muted
          async>
          <source src={banner} type="video/mp4" />
        </video>
        {/* <video
          className="absolute inset-0 w-full h-full object-cover block sm:hidden"
          autoPlay
          loop
          muted>
          <source src={phnBanner} type="video/mp4" />
        </video> */}
      </div>

      <div className="absolute inset-0 flex flex-col justify-start items-center text-center mt-8 p-4 z-10 ">
        <div className="md:max-w-5xl flex flex-col items-center">
          <h1 className="font-mango text-2xl md:text-4xl lg:text-5xl mb-4">
            Creating You From Your Brand With Click-Ready Visuals!
          </h1>

          <p className="w-fit sm:w-3/4 md:w-1/2 text-xs text-wrap sm:text-sm shadow-[inset_1px_1px_8px_rgba(0,0,0,0.6)] mt-4 p-4 border shadow-gray-500 rounded-lg">
            🔥 10x Brand Impact With CronMedia Unlimited = Ultra Creatives At
            Your Fingertips. ✨ Say Bye 👋 To Generic Stocks!
          </p>
        </div>

        <form className="w-full mt-4 max-w-lg" onKeyDown={handleSearchSubmit}>
          <div className=" h-12 py-2 relative flex items-center border border-gray-300 rounded-2xl shadow-lg bg-gray-50 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500">
            <IoMdSearch color="gray" className="ml-3 h-10 w-10" />
            <input
              type="search"
              id="default-search"
              onChange={handleSearch}
              className="flex-grow w-full p-2 text-sm text-gray-900 bg-transparent focus:outline-none"
              placeholder="Search"
              required
            />
            <select
              id="categories"
              aria-label="Select a Category"
              className="bg-transparent w-fit h-full border-l-2 text-[#493bfc] text-xs md:text-sm font-bold pl-2 pr-4 mr-2 my-2 outline-none"
              onChange={handleChange}>
              <option value="">All Categories</option>
              {totalcategory.map(category => (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </option>
              ))}
            </select>
          </div>
        </form>

        <div>
          <a
            href="#temp"
            onClick={handleScroll}
            className="absolute inset-x-0 bottom-20 hidden md:block ">
            <button className=" bg-gradient-to-t from-[#1c9cff] to-[#4e22ff] py-4 rounded-full transition-all ease-in duration-200">
              <HiMiniArrowLongDown
                size={40}
                color="white"
                className=" transition-all ease-in-out duration-300 hover:translate-y-2"
              />
            </button>
          </a>
        </div>
      </div>

      {/* chakri */}
      <div className="block sm:hidden relative">
        <div className="absolute inset-x-0 bottom-24 z-10">
          <Link to="/login" className="main group">
            <p className="rounded-full shadow-[0px_0px_20px_10px_rgba(255,255,255,0.3)]">
              <img
                className="group-hover:-translate-y-2 group-hover:translate-x-2 duration-500"
                src={arrow}
                alt="Arrow"
              />
            </p>

            <svg
              id="rotatingText"
              fill="#4e22ff"
              viewBox="0 0 200 200"
              width="150"
              height="150"
              className="pointer-events-none">
              <defs>
                <path
                  id="circle"
                  d="M 100, 100
            m -75, 0
            a 75, 75 0 1, 0 150, 0
            a 75, 75 0 1, 0 -150, 0
            "></path>
              </defs>
              <text width="400">
                <textPath
                  alignmentBaseline="top"
                  href="#circle"
                  className="text">
                  Get started Get Started Get Started
                </textPath>
              </text>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
