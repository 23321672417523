import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Modal from "../Details/Modal";
// import downloadIcon from "../asstes/Images/DownloadNew.svg";
import EasyToUse from "../Blogs/Easy-to-Use_Social_Media_Templates_for_Eye-Catching_Designs";
import Blog2 from "../Blogs/5-Mistakes-to-Avoid-When-Designing-Custom-Social-Media-Graphics";
import axios from "axios";
import { useLocation } from "react-router-dom";

const IndividualBlog = () => {
  const location = useLocation();
  const id = location.state;
  // const [hide, setHide] = useState(true);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const [dataId, setDataId] = useState([]);

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  const top = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    axios
      .post("https://www.cron-media.com:8080/blog.php")
      .then(output => {
        // console.log(output.data.data);
        setData(output.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://www.cron-media.com:8080/individualBlog.php", { id: id })
      .then(output => {
        console.log(output.data.data);
        setDataId(output.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id]); // Removed dataId from the dependency array

  return (
    <section className="leading-relaxed mx-auto w-full max-w-screen-xl  my-4 ">
      {/* <div className="text-center">
        <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
          Latest Blogs
        </span>
      </div> */}
      <div>
        {dataId?.map((value, index) => {
          return (
            <div>
              <p className="text-center text-xl md:text-3xl lg:text-4xl font-mango p-4">
                {value.blogTitle}
              </p>
              <div className="rounded-2xl shadow-xl overflow-hidden transition-all duration-500 ease-in-out">
                <img
                  src={`https://www.cron-media.com:8080/blogImages/${value.blogImg}`}
                  alt={value.blogImg}
                  loading="lazy"
                  className="w-full h-auto rounded-t-2xl object-cover"
                />
                {/* <p
                  className={`p-4 transition-all duration-500 ease-in-out line-clamp-2 ${
                    hide ? "max-h-16 overflow-hidden" : "h-fit"
                  }`}> */}
                <p className="p-0 md:p-4">
                  {(() => {
                    switch (value.blogId) {
                      case "1":
                        return <EasyToUse />;
                      case "2":
                        return <Blog2 />;
                      default:
                        return null; // You may want to return a different component for the default case
                    }
                  })()}
                </p>
                {/* {hide ? (
                  <div className="flex gap-2 p-4 items-center justify-end">
                    <button
                      className="bg-black text-sm px-3 py-2 rounded-full text-white"
                      onClick={() => setHide(false)}>
                      Learn More
                    </button>
                  </div>
                ) : null} */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-6 mt-8">
        {data?.map((value, index) => {
          return (
            <div
              // onClick={handleOpenModal}
              key={index}
              className=" rounded-2xl shadow-lg bg-white cursor-pointer transition-all duration-200 ease-in hover:shadow-xl hover:scale-[1.01]">
              <div className="">
                <img
                  src={`https://www.cron-media.com:8080/blogImages/${value.blogImg}`}
                  loading="lazy"
                  alt={value.templateImg}
                  className="w-full md:h-56 rounded-t-2xl object-cover"
                />
              </div>

              {/* <div className="flex space-x-2 mb-2 px-4">
                  <span
                    // onChange={() => setTemplateId(value.templateID)}
                    className="text-sm border border-black font-semibold text-black px-2 py-1 rounded-full">
                    {value.templateID}
                  </span>
                </div> */}

              <h1 className="text-lg font-medium my-4 px-4 line-clamp-1 ">
                {value.blogTitle}
              </h1>
              <p className="text-gray-700 text-sm mb-4 px-4 line-clamp-2">
                {value.blogDesc}
              </p>

              <Link to="/individualBlog" state={value.blogId}>
                <div className="flex justify-between items-center p-4">
                  <div className="flex gap-2 items-center">
                    <button
                      onClick={top}
                      className="bg-black text-sm  px-3 py-2 rounded-full text-white">
                      Learn More
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>

      {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal} /> */}
    </section>
  );
};

export default IndividualBlog;
