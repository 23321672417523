import Plans from "./Plans";
import Faq from "../HomePage/Faq";
import pricingBanner from "../asstes/Images/Pricing Header.png";
import pricingPhone from "../asstes/Images/Pricing HeaderPhone.png";
import { Link } from "react-router-dom";
import arrow from "../asstes/Images/Arrow4.png";

function Pricingindex({ showFooter, setShowFooter }) {
  setShowFooter(true);
  return (
    <div>
      <div className="h-fit flex flex-col justify-start sm:justify-center overflow-hidden">
        <div className="absolute z-40 md:px-32 p-4 pt-20">
          <div className="">
            <h2 className="font-mango text-2xl md:text-3xl lg:text-4xl text-left leading-tight md:leading-normal">
              Upgrade Your Brain.
            </h2>
            <p className="mt-2 text-sm md:text-base">
              Get limitless downloads with hassle-Free Cancel!
            </p>
          </div>
        </div>
        <div className="relative w-full overflow-hidden">
          <img
            className="hidden sm:block object-right w-full h-full object-cover"
            src={pricingBanner}
            alt="banner"
            loading="lazy"
          />
          <img
            className="block sm:hidden object-right w-full object-cover h-[70vh]"
            src={pricingPhone}
            alt="banner"
          />
        </div>
      </div>
      <Plans />
      <Faq />

      {/* chakri */}
      <div className="sr-only sm:not-sr-only">
        <div className=" fixed  bottom-16 z-40 p-5 right-8  ">
          <Link to="/login" className=" main group">
            <p className="rounded-full shadow-xl ">
              <img
                className=" group-hover:-translate-y-2 group-hover:translate-x-2 duration-500"
                src={arrow}
                alt="Arrow"
              />
            </p>

            <svg
              id="rotatingText"
              fill="#4e22ff"
              viewBox="0 0 200 200"
              width="150"
              height="150">
              <defs>
                <path
                  id="circle"
                  d="M 100, 100
                  m -75, 0
                  a 75, 75 0 1, 0 150, 0
                  a 75, 75 0 1, 0 -150, 0
                  "></path>
              </defs>
              <text width="400">
                <textPath
                  alignmentBaseline="top"
                  href="#circle"
                  className="text">
                  Get started Get Started Get Started
                </textPath>
              </text>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Pricingindex;
