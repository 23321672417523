import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import notfound from "../asstes/Images/404-error.svg"

const NotFoundPage = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);
    
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-5">
      <div className="max-w-md text-center">
        <img
          src={notfound}
          alt="404 illustration"
          className="w-full h-auto mb-24"
        />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          404 - Page Not Found
        </h1>
        <p className="text-gray-600 mb-8">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <Link
          to="/"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
          Go to Homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
