import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";

const Downloadbar = () => {
  const userID = Cookies.get("userID");
  const pricing = Cookies.get("pricing");
  const [downloads, setDownloads] = useState(0);

  useEffect(() => {
    const fetchDownloads = () => {
      axios
        .get(
          `https://www.cron-media.com:8080/DownloadCount.php?id=${userID}`
        )
        .then(output => {
          setDownloads(output.data.count); // Update the downloads state with API data
        })
        .catch(error => {
          console.error(error);
        });
    };

    fetchDownloads(); // Initial fetch

    const intervalId = setInterval(fetchDownloads, 1000); // Polling every 1000 ms (1 seconds)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [userID]);
  // const handleDownload = () => {
  //   if (downloads < maxDownloads) {
  //     setDownloads(downloads + 1);
  //   }
  // };

  const maxDownloads = () => {
    if (pricing === "free") {
      return 10;
    } else if (pricing === "plus") {
      return 100;
    } else {
      return 100000;
    }
  };

  const ableToDownload = () => {
    if (pricing === "pro") {
      return true;
    } else if (downloads > maxDownloads()) {
      return false;
    } else {
      return true;
    }
  };

  Cookies.set("ableToDownload", ableToDownload());

  const progressPercentage = (downloads / maxDownloads()) * 100;

  return (
    <>
      {pricing === "pro" ? null : (
        <div className="max-w-xs mx-auto bg-white p-4 rounded-lg shadow-md mt-32">
          <h2 className="text-lg font-semibold mb-2">Free Downloads</h2>
          <div className="relative pt-1 mb-4">
            <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
              <div
                style={{ width: `${progressPercentage}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-purple-500 to-blue-500"></div>
            </div>
          </div>
          <p className="text-gray-700 mb-4">
            {downloads} out of {maxDownloads()} used
          </p>
          <Link
            to="/pricing"
            // onClick={handleDownload}
            className={`w-full py-2 px-4 rounded text-white font-bold 
              bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 ${pricing === "pro" ? "hidden" : "block"}`}
            >
            Get more downloads
          </Link>
        </div>
      )}
    </>
  );
};

export default Downloadbar;
