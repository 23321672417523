import React, { useEffect, useState } from "react";
// import Modal from "../Details/Modal";
import arrow from "../asstes/Images/Arrow_5.svg";
import axios from "axios";
import { Link } from "react-router-dom";

const Blogs = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    axios
      .post("https://www.cron-media.com:8080/blog.php")
      .then(output => {
        console.log(output.data.data);
        setData(output.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <section className="leading-relaxed my-12 px-5 xl:px-0 max-w-screen-xl mx-auto">
      <div className="text-center">
        <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
          BLOGS
        </span>
        <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3">
          Browse Blogs
        </h1>
        <p className="text-gray-600 mx-auto text-lg font-clash my-3 max-w-xl">
          Dive into the World of Insights and Discover New Perspectives
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-6 mt-8">
        {data?.map((value, index) => (
          <Link to="/individualBlog" state={value.blogId}>
            <div
              key={index}
              // onClick={handleOpenModal}
              className=" rounded-2xl shadow-lg bg-white cursor-pointer transition-all duration-200 ease-in hover:shadow-xl hover:scale-[1.01]">
              <div className="">
                <img
                  src={`https://www.cron-media.com:8080/blogImages/${value.blogImg}`}
                  alt={value.blogImg}
                  loading="lazy"
                  className="w-full h-56 rounded-t-2xl object-cover"
                />
              </div>
              <h3 className="text-lg font-medium my-4 px-4 line-clamp-1">
                {value.blogTitle}
              </h3>
              <p className="text-gray-700 text-sm mb-4 px-4 line-clamp-2">
                {value.blogDesc}
              </p>
              <div className="flex justify-between items-center p-4">
                <div className="flex gap-2 items-center">
                  <Link
                    to="/individualBlog"
                    state={value.blogId}
                    className="bg-black text-sm px-3 py-2 rounded-full text-white">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <Link to={"/individualBlog"} className="flex justify-center mt-8">
        <button className="bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff] flex items-center gap-2 px-6 text-sm tracking-wide py-4 rounded-full text-white">
          Load More{" "}
          <span>
            <img src={arrow} alt="symbol" />
          </span>
        </button>
      </Link>
      {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal} /> */}
    </section>
  );
};

export default Blogs;
