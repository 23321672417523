import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import glits from "../asstes/Images/glitstudio.webp";
import oyo from "../asstes/Images/oyo.webp";
import zoom from "../asstes/Images/zoomcar.webp";
import vaaree from "../asstes/Images/vaaree.webp";
import skilcamp from "../asstes/Images/skilcamp.webp";

const TestimonialCard = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const testimonials = [
    {
      image: glits,
      content:
        "Cron-Media's video templates are a game-changer for our content production. They're easy to use and look professional.",
      name: "Glitstudio",
      company: "Glitstudio",
    },
    {
      image: skilcamp,
      content:
        "We were impressed with the quality and variety of templates offered. Cron-Media's designs made our marketing materials stand out.",
      name: "Sambit Mahala",
      company: "Skilcamp",
    },
    {
      image: zoom,
      name: "Srishti Mukharya",
      company: "Zoomcar",
      content:
        "The creative templates from Cron-Media helped us achieve a polished and cohesive look for our new campaign. Highly recommended!",
    },
    {
      image: oyo,
      name: "Somya Maheshwari",
      company: "OYO",
      content:
        "Cron-Media's templates transformed our brand presentation! The designs were fresh and engaging, perfectly capturing our vision.",
    },
    {
      image: vaaree,
      name: "Vikas Kumar",
      company: "VAAREE",
      content:
        "The video templates provided by Cron-Media were exactly what we needed. They saved us hours of work, looked fantastic, and enhanced our brand!",
    },
  ];

  return (
    <section className="leading-relaxed w-full h-fit mt-12 bg-gradient-to-bl from-[#1c9dff58] to-[#4e22ff66]">
      <div className="px-5 xl:px-0 max-w-screen-xl mx-auto">
        <div className="text-center my-4 pt-8">
          <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-2 tracking-widest my-3">
            TESTIMONIALS
          </span>
          <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3 capitalize">
            What They Say About Us
          </h1>
        </div>

        <Carousel
          responsive={responsive}
          itemClass="carousel-item-padding-40-px"
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="max-w-sm my-4 rounded-2xl shadow-xl bg-white p-6 border border-gray-200 mx-3 md:h-[400px] sm:h-[300px] h-[370px] flex flex-col justify-between">
              <div>
                <div className="flex justify-start mb-4">
                  <img src={testimonial.image} className="w-20 h-20 object-contain" alt={testimonial.company} />
                </div>
                <blockquote className="text-left">
                  <p className="text-gray-700 mb-4 ">
                    {testimonial.content}
                  </p>
                </blockquote>
              </div>
              <div className="text-left mt-auto">
                <p className="font-semibold text-gray-900">{testimonial.name}</p>
                <p className="text-sm text-gray-500">{testimonial.company}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default TestimonialCard;
