import React from "react";

const CategoryCards = () => {
  return (
    <section className="py-12 bg-white">
      <div className="text-center mb-8">
        <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-lg text-sm mb-4 tracking-widest my-3">
          STEPS
        </span>
        <h2 className="text-3xl font-bold text-black mb-2 font-mango">
          Get Hands-On Touch-Friendly Designs
        </h2>
        <p className="text-gray-600">
          Templates For Promotions, Product Showcases, Customer Testimonials,
          And Announcements.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-screen-xl mx-auto px-4">
        {[
          {
            title: "Finest Design Quality",
            description:
              "Get unparalleled visuals with our templates. Curated from the expertise of premier creative minds. Take your branding to the next three levels.",
          },
          {
            title: "Effortless Customization",
            description:
              "Quickly personalize your ideal template using our intuitive editing tools. Seamlessly adjust colors, fonts, images, and layouts. Bring your vision to life in seconds.",
          },
          {
            title: "Endless Creative Freedom",
            description:
              "From eye-catching templates showcasing promotion, event invitations, buyer surveys, product guides, and manuals to compelling client diaries, press releases, reports, presentations, and social infographics, we offer endless solutions. Ensuring your brand stands out perfectly.",
          },
          {
            title: "Boost Your Productivity",
            description:
              "Optimize your workflow with our efficient templates, giving your team the tools to produce quality designs faster and cutting down on time spent by 3x times.",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-2xl p-6 text-left border border-purple-300">
            <div className="flex items-center mb-4">
              <div className="bg-blue-600 text-white rounded-full w-10 h-10 flex items-center justify-center text-lg font-bold">
                {index + 1}
              </div>
            </div>
            <h3 className="text-xl font-semibold text-black mb-2">
              {item.title}
            </h3>
            <p className="text-gray-600">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CategoryCards;
