import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import Downloadbar from "./Downloadbar";

const SidebarModal = ({ sideindex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [showList, setShowList] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const API = "https://www.cron-media.com:8080/category.php";

  useEffect(() => {
    axios
      .post(API)
      .then(res => {
        setCategory(res.data.data);
      })
      .catch(err => {
        console.error("Error fetching categories:", err);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setShowList(true);
      }, 250);
      return () => clearTimeout(timer);
    } else {
      setShowList(false);
    }
  }, [isOpen]);

  return (
    <>
      {/* Toggle Button */}
      <div
        className={`fixed h-10 w-10 top-14 z-40 cursor-pointer flex justify-center items-center m-4 p-2 bg-gray-600 rounded-full transition-all duration-300 ${
          isOpen ? "left-60" : "left-0"
        }`}>
        <button onClick={toggleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            viewBox="0 -960 960 960"
            fill="#e8eaed">
            {isOpen ? (
              <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
            ) : (
              <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
            )}
          </svg>
        </button>
      </div>

      {/* Modal */}
      <Transition
        show={isOpen}
        enter="transition-transform duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full">
        <div className="fixed inset-0 z-30">
          {/* Modal content */}
          <div className="fixed top-14 left-0 h-screen w-60 bg-white shadow-lg">
            <div
              className={`h-full shadow-xl bg-[#f8fafc] transition-all duration-300 ${
                isOpen ? "w-60" : "w-0"
              }`}>
              <div
                className={`${
                  showList ? "block" : "hidden"
                } text-black w-full`}>
                <ul>
                  <li
                    key="all"
                    onClick={() => {
                      sideindex("");
                      toggleSidebar();
                    }}
                    className="p-2 hover:bg-gray-300 cursor-pointer">
                    All
                  </li>
                  {category.map((element, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        sideindex(element.categoryId);
                        toggleSidebar();
                      }}
                      className="p-2 hover:bg-gray-300 cursor-pointer">
                      {element.categoryName}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={`${showList ? "block" : "hidden"} w-full`}>
                <Downloadbar />
              </div>
            </div>
          </div>
          {/* Background overlay */}
          {/* <div className="absolute inset-0" onClick={toggleSidebar} /> */}
        </div>
      </Transition>
    </>
  );
};

export default SidebarModal;
