const PrivacyPolicy = ({ showFooter, setShowFooter }) => {
  setShowFooter(true);

  return (
    <div className="bg-slate-200 py-6 px-10">
      <main class="container mx-auto p-6 bg-white rounded-lg shadow-lg max-w-6xl">
        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2  mb-4">
            1. Introduction
          </h2>
          <p class="text-gray-700 mb-4">
            Welcome to cronMedia! We are committed to protecting your privacy
            and ensuring your experience is safe and secure. This Privacy Policy
            explains how we collect, use, and protect your information when you
            use our website.
          </p>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2  mb-4">
            2. Information We Collect
          </h2>
          <p class="text-gray-700 mb-4">
            We may collect the following types of information:
          </p>
          <ul class="list-disc pl-6 text-gray-700 mb-4">
            <li class="mb-2">
              Personal Identification Information: Name, email address, and
              other details you provide when registering or contacting us.
            </li>
            <li class="mb-2">
              Usage Data: Information about how you interact with our site,
              including IP addresses, browser types, and pages visited.
            </li>
            <li class="mb-2">
              Cookies: Small data files stored on your device to enhance your
              browsing experience.
            </li>
          </ul>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2  mb-4">
            3. How We Use Your Information
          </h2>
          <p class="text-gray-700 mb-4">
            We use the information we collect to:
          </p>
          <ul class="list-disc pl-6 text-gray-700 mb-4">
            <li class="mb-2">Provide and improve our services.</li>
            <li class="mb-2">
              Respond to your inquiries and provide customer support.
            </li>
            <li class="mb-2">
              Send periodic emails with updates or promotional content (you can
              opt out at any time).
            </li>
            <li class="mb-2">
              Analyze usage trends to enhance user experience.
            </li>
          </ul>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2  mb-4">
            4. How We Protect Your Information
          </h2>
          <p class="text-gray-700 mb-4">
            We implement a variety of security measures to maintain the safety
            of your personal information. This includes:
          </p>
          <ul class="list-disc pl-6 text-gray-700 mb-4">
            <li class="mb-2">Encryption to protect sensitive data.</li>
            <li class="mb-2">
              Access controls to limit who can view your information.
            </li>
            <li class="mb-2">
              Regular security reviews to prevent unauthorized access.
            </li>
          </ul>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            5. Sharing Your Information
          </h2>
          <p class="text-gray-700 mb-4">
            We do not sell or trade your personal information. We may share
            information with trusted third parties who assist us in operating
            our website, as long as they agree to keep this information
            confidential.
          </p>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            6. Your Choices
          </h2>
          <p class="text-gray-700 mb-4">You can choose to:</p>
          <ul class="list-disc pl-6 text-gray-700 mb-4">
            <li class="mb-2">
              Opt out of receiving marketing emails by following the unsubscribe
              instructions.
            </li>
            <li class="mb-2">
              Access, update, or delete your personal information by contacting
              us directly.
            </li>
          </ul>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            7. Changes to This Privacy Policy
          </h2>
          <p class="text-gray-700 mb-4">
            We may update this Privacy Policy from time to time. We will notify
            you of any significant changes by posting the new policy on this
            page and updating the effective date.
          </p>
        </section>

        <section class="mb-8">
          <h2 class="text-xl font-semibold border-b-2 border-gray-200 pb-2 mb-4">
            8. Contact Us
          </h2>
          <p class="text-gray-700 mb-4">
            If you have any questions about this Privacy Policy or our
            practices, please contact us at:
          </p>
          <p class="text-gray-700 mb-4">
            Email:{" "}
            <a
              href="mailto:info@cron-media.com"
              class="text-blue-600 hover:underline">
              info@cron-media.com
            </a>
          </p>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
