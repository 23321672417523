import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
// import MainContent from "./MainContent";
import DashboardFooter from "../Footer/DashboardFooter";
import DashboardHeader from "../Header/DashboardHeader";
import Sidebar from "../Sidebar/Sidebar";
import SidebarModal from "../Sidebar/SidebarModal";
import Download from "./Download";
// import MainHighlights from "./MainHighlights";
// import UserCollection from "./UserCollection";
import withAuth from "../Dashboard/DashboardAuth";

function DownloadPage({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  // const [isAtBottom, setIsAtBottom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const email = Cookies.get("email");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [catindex, setCatIndex] = useState();
  const [searchdata, setSearchData] = useState("");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState();
  const [category, setCategory] = useState([]);
  

  const handleData = (childDataindex, childDatasearch) => {
    setCatIndex(childDataindex);
    setSearchData(childDatasearch);
  };

  const handleMainData = (childCount, childData, childSearch) => {
    setCount(childCount);
    setCategory(childData);
    setSearch(childSearch);
    setIsLoading(false);
  };

  // Using a Set to store unique categories
  const uniqueCategories = new Set();

  // Extracting unique categories if category array is available
  if (Array.isArray(category)) {
    category.forEach(template => {
      template.Category.forEach(category => {
        uniqueCategories.add(category);
      });
    });
  }

  const uniqueCategoryArray = Array.from(uniqueCategories);

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY || document.documentElement.scrollTop;
  //     const scrollHeight = document.documentElement.scrollHeight;
  //     const clientHeight =
  //       window.innerHeight || document.documentElement.clientHeight;

  //     setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   handleScroll(); // Initial check

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (searchdata || catindex) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [searchdata, catindex]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 500);
  }, []);

  return (
    <>
      <DashboardHeader email={email} handleData={handleData} />
      <div className="flex">
        {/* Sidebar */}
        <div className="sticky top-16 h-full bg-white">
          {/* Sidebar for larger screens */}
          <div className="h-full hidden md:block">
            <Sidebar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              sideindex={handleData}
            />
          </div>

          {/* Sidebar modal for smaller screens */}
          <div className="h-full block md:hidden">
            <SidebarModal
              // isOpen={isModalOpen}
              // onClose={handleCloseModal}
              sideindex={handleData}
            />
          </div>
        </div>

        {/* main content */}
        <main
          className="md:ml-10 flex-1
             overflow-y-auto transition-all ease-in-out duration-300">
          {/* <MainHighlights /> */}
          {isLoading ? (
            <div className="h-[70vh] flex justify-center items-center">
              <InfinitySpin
                visible={true}
                width="200"
                color="#1d4ed7"
                ariaLabel="infinity-spin-loading"
              />
            </div>
          ) : (
            <div className="leading-relaxed w-full mx-auto px-4 md:px-8">
              <div className="my-7">
                <div className="flex flex-col md:flex-row justify-between gap-8 md:gap-32">
                  <div className="flex-1">
                    <h1 className="text-3xl text-gray-800 font-semibold font-mango my-3">
                      {search
                        ? `"${search}" ${uniqueCategoryArray.join(" & ")}`
                        : "Browse Thousands Of Digital Assets"}
                    </h1>
                    <p className="text-gray-600 text-sm font-clash my-3">
                      {search
                        ? `Browse through all ${count} template assets. Take a look at the entire library.`
                        : "Find millions of high-quality and hand-reviewed images, graphics, videos, and everything you need to make your project stand out. Unlimited downloads of any item with an Envato Elements subscription. Take a look at our pricing page!"}
                    </p>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <Link to="/dashboard">
                      <button className="bg-black text-white text-sm px-4 py-2 rounded-xl">
                        All Templates
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="my-14">
                <Download
                  isOpen={isOpen}
                  catindex={catindex}
                  searchdata={searchdata}
                  count_n_category={handleMainData}
                />
              </div>
            </div>
          )}
        </main>
      </div>
      <DashboardFooter />
    </>
  );
}

export default withAuth(DownloadPage);
