import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineAccountCircle } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { RiContactsBook3Line } from "react-icons/ri";
import { MdDownloadForOffline } from "react-icons/md";
import { BsQuestionCircleFill } from "react-icons/bs";
import { FaGift } from "react-icons/fa6";
import axios from "axios";
import Cookies from "js-cookie";

const ProfileDropdown = email => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post("https://www.cron-media.com:8080/profile.php", {
        email: email.email,
      })
      .then(output => {
        console.log(output);
        const data = output.data.data[0];
         console.log(data);
        setData(data);
        Cookies.set("pricing", data.Pricing, {expires: 1});
        // console.log(data.Pricing);
        if (data.Pricing === "free") {
          setPrice("Free");
        }
        else if(data.Pricing === "plus") {
          setPrice("Plus");
        } else {
          setPrice("Pro");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [email.email]);

  // for Adding templates to user table
  Cookies.set("userID", data.userID)

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setIsOpen(true);
    // localStorage.removeItem("userLogin");
    Cookies.remove("status");
    Cookies.remove("email");
    Cookies.remove("userID");
    Cookies.remove("pricing");
    Cookies.remove("ableToDownload");
    navigate("/");
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="focus:outline-none flex justify-center items-center">
        {data.Image ? (
          <img
            src={`https://www.cron-media.com:8080/userImages/${data.Image}`}
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
        ) : (
          <MdOutlineAccountCircle size={50} />
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-2xl shadow-xl z-50">
          <div className="pt-2">
            <button
              onClick={() => handleLogout()}
              className="p-2 mr-1 absolute right-0 rounded-full hover:bg-gray-100">
              <LuLogOut size={24} />
            </button>
            <div className="pt-4">
              <Link to="#">
                <div className="flex flex-col justify-center items-center w-auto">
                  {data.Image ? (
                    <img
                      src={`https://www.cron-media.com:8080/userImages/${data.Image}`}
                      alt="Profile"
                      className="w-20 h-20 rounded-full p-1 border"
                    />
                  ) : (
                    <MdOutlineAccountCircle size={70} />
                  )}
                  <h2 className="font-semibold text-base">{data.name}</h2>
                  <h3 className="font-small text-slate-500 text-center text-sm">
                    {data.email}
                  </h3>
                </div>
              </Link>
            </div>

            <div className="pt-4">
              <Link to="/profile">
                <div className="text-sm flex gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100">
                  <RiContactsBook3Line size={25} />
                  <p className="w-full">Profile</p>
                </div>
              </Link>
              <Link to="/download">
                <div className="text-sm flex gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100">
                  <MdDownloadForOffline size={30} />
                  <p className="w-full">All Downloads</p>
                </div>
              </Link>
              <Link to="/contact">
                <div className="text-sm flex gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100">
                  <BsQuestionCircleFill size={24} />
                  <p className="w-full">Help Center</p>
                </div>
              </Link>
              <Link to="#">
                <div className="text-sm flex gap-3 px-4 py-2 text-gray-700 hover:bg-gray-100">
                  <FaGift size={24} color="#4e22ff" />
                  <p className="w-full text-[#4e22ff]">
                    Invite Friends and get $10!
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="p-2 bg-[#dfecff] rounded-b-2xl">
            <Link to="#">
              <div className="flex justify-center items-center w-auto">
                {data.Image ? (
                  <img
                    src={`https://www.cron-media.com:8080/userImages/${data.Image}`}
                    alt="Profile"
                    className="w-12 h-12 rounded-full"
                  />
                ) : (
                  <MdOutlineAccountCircle size={40} />
                )}
                <div className="px-4">
                  <div className="flex justify-center items-center gap-1">
                    <h2 className="font-semibold text-xs">{data.name}</h2>
                    <p className="px-2 py-1 bg-[#365eff81] rounded-md text-white text-xs text-center">
                      {price}
                    </p>
                  </div>
                  <h3 className="font-small text-slate-500 text-center text-xs">
                    {data.email}
                  </h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
