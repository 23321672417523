import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import "tailwindcss/tailwind.css";
import withAdminAuth from "../AdminAuth";

const AddCollections = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const navigate = useNavigate();
  const [collectionID, setCollectionID] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [collectionDesc, setCollectionDesc] = useState("");
  const [smallPreview, setSmallPreview] = useState([]);
  const [largePreview, setLargePreview] = useState([]);
  const [collectionFile, setCollectionFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [assets, setAssets] = useState("");
  const [totalcategory, setTotalCategory] = useState([]);

  const onDropSmallPreview = acceptedFiles => {
    setSmallPreview(acceptedFiles);
  };

  const onDropLargePreview = acceptedFiles => {
    setLargePreview(acceptedFiles);
  };

  const onDropTemplateFile = acceptedFiles => {
    setCollectionFile(acceptedFiles[0]);
  };

  const handleCategoryChange = event => {
    const value = event.target.value;
    setCategories(prev =>
      prev.includes(value)
        ? prev.filter(category => category !== value)
        : [...prev, value]
    );
  };

  const handleAdd = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Collection_ID", collectionID);
    formData.append("Collection_Name", collectionName);
    formData.append("Collection_Desc", collectionDesc);
    smallPreview.forEach((file, index) => {
      formData.append(`Small_preview[${index}]`, file);
    });
    largePreview.forEach((file, index) => {
      formData.append(`Large_preview[${index}]`, file);
    });
    formData.append("Collection_File", collectionFile);
    categories.forEach((Category, index) => {
      formData.append(`Category[${index}]`, Category);
    });
    tags.forEach((Tag, index) => {
      formData.append(`Tags[${index}]`, Tag);
    });
    formData.append("Assets", assets);

    // Show loading toast
    const toastId = toast.loading("Uploading...");

    try {
      const response = await axios.post(
        "https://www.cron-media.com:8080/adminCollection.php?id=",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === undefined) {
        console.log(response.data.status);
        toast.success("Uploaded Successfully ✌️", {
          id: toastId, // Update the loading toast with success
        });
        navigate("/adminCollections");
      } else {
        console.log(response.data.status);
        toast.error("Upload Unsuccessful 💀", {
          id: toastId, // Update the loading toast with error
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Upload Unsuccessful 💀", {
        id: toastId, // Update the loading toast with error
      });
    }
  };

  // Fetch categories
  useEffect(() => {
    axios.get(`https://www.cron-media.com:8080/category.php`).then(response => {
      const catdata = response.data;
      console.log(catdata.data);
      setTotalCategory(catdata.data);
    });
  }, []);

  // Rules toast (you can trigger this at the appropriate time)
  useEffect(() => {
    toast(
      <div className="flex flex-col justify-center items-center">
        <h1 className="font-bold">Rules Of Entering Data</h1>
        <ul className="list-disc">
          <li>Enter tags normally without enclosing them in brackets.</li>
          <li>Please put a comma after every tag.</li>
          <li>Don't use the same file names or titles.</li>
        </ul>
      </div>,
      {
        duration: 6000,
      }
    );
  }, []);

  return (
    <div className="container max-w-4xl mx-auto p-4 overflow-auto">
      <h1 className="font-mango text-3xl text-center my-4">Add Collection</h1>
      <form
        onSubmit={handleAdd}
        className="space-y-4"
        encType="multipart/form-data">
        <div>
          <label className="block text-gray-700">Collection ID</label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300 rounded"
            value={collectionID}
            onChange={e => setCollectionID(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-700">Collection Name</label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded"
            value={collectionName}
            onChange={e => setCollectionName(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-700">Collection Description</label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded"
            value={collectionDesc}
            onChange={e => setCollectionDesc(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-700">Small Preview</label>
          <Dropzone onDrop={onDropSmallPreview}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 cursor-pointer">
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                <div className="mt-2">
                  {smallPreview.map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))}
                </div>
              </>
            )}
          </Dropzone>
        </div>
        <div>
          <label className="block text-gray-700">Large Preview</label>
          <Dropzone onDrop={onDropLargePreview}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 cursor-pointer">
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                <div className="mt-2">
                  {largePreview.map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))}
                </div>
              </>
            )}
          </Dropzone>
        </div>
        <div>
          <label className="block text-gray-700">Collection File</label>
          <Dropzone onDrop={onDropTemplateFile} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 cursor-pointer">
                  <input {...getInputProps()} />
                  <p>Drag & drop a file here, or click to select a file</p>
                </div>
                <div>
                  {collectionFile ? <p>{collectionFile.name}</p> : null}
                </div>
              </>
            )}
          </Dropzone>
        </div>
        <div>
          <label className="block text-gray-700">Categories</label>
          <div className="flex flex-wrap">
            {totalcategory.map(category => {
              return (
                <label key={category.categoryName} className="mr-4">
                  <input
                    type="checkbox"
                    name="category"
                    value={category.categoryName}
                    onChange={handleCategoryChange}
                  />
                  {category.categoryName}
                </label>
              );
            })}
          </div>
        </div>
        <div>
          <label className="block text-gray-700">Tags</label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded"
            value={tags}
            onChange={e => setTags(e.target.value.split(", "))}
          />
        </div>
        <div>
          <label className="block text-gray-700">Assets</label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300 rounded"
            value={assets}
            onChange={e => setAssets(e.target.value)}
          />
        </div>
        <div>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default withAdminAuth(AddCollections);
