import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AdminNavbar from "../AdminNavbar";
import withAdminAuth from "../AdminAuth";

const AdminCollections = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const [collections, setCollections] = useState([]);
  const [apiUser, setApiUser] = useState([]);

  const fetchCollections = async () => {
    try {
      const response = await axios.get(
        `https://www.cron-media.com:8080/adminCollection.php?id=`
      );
      setCollections(response.data);
      setApiUser(response.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  const handelSearch = e => {
    let apiData = [...apiUser];
    console.log(e.target.value);
    const result = apiData.filter(element => {
      if (e.target.value === "") {
        return true;
      } else if (
        element.Collection_Name?.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      ) {
        return true;
      } else if (
        element.Categories?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      }
      // else if (
      //   element.Tags?.toLowerCase().includes(e.target.value.toLowerCase())
      // ) {
      //   return true;
      // }
      return false;
    });
    setCollections(result);
  };

  // Delete template
  const handleDelete = async id => {
    try {
      if (window.confirm("Are you sure you want to delete this collection?")) {
        await axios.delete(
          `https://www.cron-media.com:8080/adminCollection.php?id=${id}`
        );
        fetchCollections();
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  return (
    <>
      <AdminNavbar />
      <div className="container mx-auto p-4">
        <h1 className=" font-mango text-3xl text-center my-4">Collections</h1>
        <div className="relative">
          <input
            type="text"
            placeholder="Search collections"
            className="p-2 border border-gray-300 rounded mb-4 w-full"
            onChange={e => handelSearch(e)}
          />
          <Link to={`/addCollection`}>
            <button className="fixed bottom-8 right-8 bg-blue-500 p-4 rounded-full shadow-lg flex items-center justify-center h-16 w-16">
              <p className="text-center text-6xl text-white">+</p>
            </button>
          </Link>
        </div>

        <div className="overflow-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="py-2 px-4 border-r">Collection ID</th>
                <th className="py-2 px-4 border-r">Collection Name</th>
                <th className="py-2 px-4 border-r">Small Preview</th>
                <th className="py-2 px-4 border-r">Large Preview</th>
                <th className="py-2 px-4 border-r">Collection File</th>
                <th className="py-2 px-4 border-r">Category</th>
                <th className="py-2 px-4 border-r">Tags</th>
                <th className="py-2 px-4 border-r">Number of Assets</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {collections?.map(collection => (
                <tr key={collection.ID} className="border-b">
                  <td className="py-2 px-4 border-r">{collection.ID}</td>
                  <td className="py-2 px-4 border-r">
                    {collection.Collection_Name}
                  </td>
                  <td className="py-2 px-4 border-r">
                    <div className="flex flex-wrap">
                      {collection.Small_preview?.map((file, index) => (
                        <div key={index} className="mr-2 mb-2">
                          <img
                            src={`https://www.cron-media.com:8080/Collections/Images/${file}`}
                            alt={file}
                            className="rounded-md h-16 w-16"
                          />
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-r">
                    <div className="flex flex-wrap">
                      {collection.Large_preview?.map((file, index) => (
                        <div key={index} className="mr-2 mb-2">
                          <img
                            src={`https://www.cron-media.com:8080/Collections/Images/${file}`}
                            alt={file}
                            className="rounded-md h-16 w-16"
                          />
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-r">
                    📂{collection.Collection_File}
                  </td>
                  <td className="py-2 px-4 border-r">
                    {collection.Categories}
                  </td>
                  <td className="py-2 px-4 border-r">
                    {collection.Tags?.join(",")}
                  </td>
                  <td className="py-2 px-4 border-r">{collection.Assets}</td>
                  <td className="py-2 px-4 flex flex-row flex-nowrap">
                    <Link
                      to={`/updateCollection/${collection.ID}`}
                      className="bg-green-500 text-white p-2 rounded mr-2">
                      Update
                    </Link>
                    <button
                      className="bg-red-500 text-white p-2 rounded"
                      onClick={() => handleDelete(collection.ID)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withAdminAuth(AdminCollections);
