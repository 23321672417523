import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { MdOutlineAccountCircle } from "react-icons/md";
import logo from "../asstes/Images/Property 1=LogoOnWhite.svg";
import whitelogo from "../asstes/Images/footerlogowhite.svg";
import person from "../asstes/Images/Profile2.svg";
import personwhite from "../asstes/Images/Profile1.svg";

function Header() {
  const location = useLocation();
  const status = Cookies.get("status");

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const email = Cookies.get("email");
  const [search, setSearch] = useState();

  const navigate = useNavigate();

  const handleSearch = e => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSearchSubmit = e => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      Cookies.set("searchterm", search, { expires: 1 });
      Cookies.set("categoryIndex", "", { expires: 1 });
      if (Cookies.get("status") === "active") {
        navigate("/dashboard");
      } else {
        if (location.pathname === "/template") {
          window.location.reload();
        } else {
          navigate("/template");
        }
      }
    }
  };

  useEffect(() => {
    axios
      .post("https://www.cron-media.com:8080/profile.php", {
        email: email,
      })
      .then(output => {
        console.log(output);
        const data = output.data.data[0];
        console.log(data);
        setData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [email]);

  return (
    <nav
      className={`w-full sticky top-0 ${
        location.pathname === "/"
          ? "bg-white"
          : "bg-gradient-to-bl from-[#1c9cff] to-[#4e22ff]"
      } border-b border-gray-200 px-4 py-2.5 shadow-md backdrop-blur-xl z-50`}>
      <div className="max-w-screen-xl flex flex-nowrap items-center justify-between mx-auto">
        {/* image and buttons */}
        <div className="flex ">
          <Link to={`${status === "active" ? "/dashboard" : "/"}`} className="flex">
            {location.pathname === "/" ? (
              <img
                src={logo}
                className="h-6 mr-6 sm:h-9"
                alt="CronMedia Logo"
              />
            ) : (
              <img
                src={whitelogo}
                className="h-6 mr-6 sm:h-9"
                alt="CronMedia Logo"
              />
            )}
          </Link>
          <div className="w-full ml-10 my-auto hidden lg:flex lg:w-auto">
            <ul
              className={`flex flex-col mt-4 ${
                location.pathname === "/" ? "text-gray-700" : "text-white"
              }  font-medium md:flex-row md:space-x-8 md:mt-0`}>
              <li>
                <a
                  href="/template"
                  className={`${
                    location.pathname === "/"
                      ? "hover:text-blue-700"
                      : "hover:bg-[#72b0e389] "
                  } block py-2 pr-4 pl-3  rounded-md p-2 `}>
                  Templates
                </a>
              </li>
              {/* <li>
                <Link
                  to="#"
                  className={`${
                    location.pathname === "/"
                      ? "hover:text-blue-700"
                      : "hover:bg-[#72b0e389] "
                  } block py-2 pr-4 pl-3  rounded-md p-2 `}>
                  Gen AI
                </Link>
              </li> */}
              <li>
                <Link
                  to="/pricing"
                  className={`${
                    location.pathname === "/"
                      ? "hover:text-blue-700"
                      : "hover:bg-[#72b0e389] "
                  } block py-2 pr-4 pl-3  rounded-md p-2 `}>
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* search bar and login */}
        <div className="flex items-end gap-4">
          <div className="flex items-center justify-center">
            <input
              onChange={handleSearch}
              onKeyDown={handleSearchSubmit}
              value={Cookies.get("TagSearch")}
              className="h-fit border rounded-full py-2 px-4 text-sm text-slate-600 mx-2 md:mx-10 hidden lg:block focus:ring-1 focus:outline-none"
              placeholder="Search"
            />

            {status === "active" ? (
              data.Image !== null &&
              data.Image !== undefined &&
              data.Image !== "" ? (
                <Link to="/dashboard">
                  <img
                    src={`https://www.cron-media.com:8080/userImages/${data.Image}`}
                    alt="Profile"
                    className="w-10 h-10 rounded-full"
                  />
                </Link>
              ) : (
                <Link to="/dashboard">
                  {location.pathname === "/" ? (
                    <MdOutlineAccountCircle size={40} />
                  ) : (
                    <MdOutlineAccountCircle size={40} color="white" />
                  )}
                </Link>
              )
            ) : (
              <Link
                to="login"
                className={`group flex items-center border ${
                  location.pathname === "/"
                    ? "border-[#4e22ff] bg-white hover:bg-gradient-to-r hover:from-[#1c24ff] hover:to-[#4e22ff]"
                    : "border-white bg-[#2e71ff] hover:bg-white hover:border-[#9e8de0] "
                }    rounded-xl  h-fit p-1 px-3`}>
                <img
                  src={`${location.pathname === "/" ? personwhite : person}`}
                  alt="login"
                  className="hidden group-hover:block p-1"
                />
                <img
                  src={`${location.pathname === "/" ? person : personwhite}`}
                  alt="login"
                  className="block group-hover:hidden p-1"
                />
                <h3
                  className={`p-1 text-sm text-center font-medium   ${
                    location.pathname === "/"
                      ? "text-transparent bg-gradient-to-r from-[#1c9cff] to-[#4e22ff] group-hover:text-white"
                      : "text-white  group-hover:text-[#4e22ff]"
                  }  bg-clip-text hidden md:block `}>
                  Login or Signup
                </h3>
              </Link>
            )}
          </div>

          {/* mob button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-[#2684ff66] focus:outline-none "
            aria-controls="mobile-menu"
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>

            {!isOpen ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill={`${location.pathname === "/" ? "currentcolor" : "white"}`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 010 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke={`${
                  location.pathname === "/" ? "currentcolor" : "white"
                }`}
                className="size-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      {/* Mobile menu items */}
      <div className={`lg:hidden mt-2 overflow-hidden transition-max-height duration-300 ease-in-out ${isOpen ? 'max-h-96' : 'max-h-0'}`}>
        <div className="py-2">
          <Link
            to="/template"
            onClick={() => setIsOpen(!isOpen)}
            className={`block text-sm py-2 pr-4 rounded md:bg-transparent md:p-0 hover:text-blue-700 transition-colors duration-300 ${
              location.pathname === "/" ? "text-black" : "text-white"
            }`}>
            Templates
          </Link>
          {/* <Link
            to="#"
            onClick={() => setIsOpen(!isOpen)}
            className={`block text-center py-2 pr-4 pl-3 rounded md:bg-transparent md:p-0 hover:text-blue-700 transition-colors duration-300 ${
              location.pathname === "/" ? "text-black" : "text-white"
            }`}>
            Gen AI
          </Link> */}
          <Link
            to="/pricing"
            onClick={() => setIsOpen(!isOpen)}
            className={`block text-sm py-2 pr-4 rounded md:bg-transparent md:p-0 hover:text-blue-700 transition-colors duration-300 ${
              location.pathname === "/" ? "text-black" : "text-white"
            }`}>
            Pricing
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
