import React from "react";
import { useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import withAdminAuth from "./AdminAuth";

const AdminPanel = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  return (
    <>
      <AdminNavbar />
      <div className="container mx-auto p-4 flex justify-center items-center h-[80vh]">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          <div className="bg-black bg-opacity-10 h-[70vh] p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-2">
              Total number of templates
            </h2>
            <p className="text-gray-700">123</p>
          </div>
          <div className="bg-black bg-opacity-10 h-[70vh] p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-2">Number of new uploads</h2>
            <p className="text-gray-700">45</p>
          </div>
          <div className="bg-black bg-opacity-10 h-[70vh] p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-2">
              Most downloaded templates
            </h2>
            <p className="text-gray-700">Template A</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAdminAuth(AdminPanel);
