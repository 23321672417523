import { useGoogleLogin } from "@react-oauth/google";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
import google from "../../asstes/Images/google.png"

function Loginindex({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const navigate = useNavigate();

  const [signup, setSignUp] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  // const googleSignup = useGoogleLogin({
  //   onSuccess: async tokenResponse => {
  //     try {
  //       const userInfo = await axios.get(
  //         "https://www.googleapis.com/oauth2/v3/userinfo",
  //         {
  //           headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
  //         }
  //       );
  //       const result = userInfo.data;
  //       console.log(result);

  //       const googlesub = result.sub;
  //       const email = result.email;
  //       const name = result.name;
  //       const img = result.picture;

  //       const response = await axios.post(
  //         "https://www.cron-media.com:8080/loginValidation.php",
  //         { email }
  //       );

  //       if (response.data.status === true) {
  //         setSignUp(true);
  //         // toast.error("User already Registered!");
  //       } else {
  //         navigate("/register", { state: { name, email, img, googlesub } });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  // });

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      try {
        const userInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
        );
        const result = userInfo.data;
        console.log(result);
        const googlesub = result.sub;
        const email = result.email;
        const name = result.name;
        const img = result.picture;
        const googleflag = "1";

        const response = await axios.post(
          "https://www.cron-media.com:8080/loginWithGoogle.php",
          {
            email,
            googlesub,
          }
        );
        console.log(response);
        if (response.data.status === true) {
          navigate(
            "/dashboard",
            { state: { email } },
            // localStorage.setItem("userLogin", true),
            toast.success("Welcome To Your Dashboard!")
          );
          Cookies.set("status", "active", { expires: 1 });
          Cookies.set("email", email, { expires: 1 });
        } else {
          navigate("/register", {
            state: { name, email, img, googlesub, googleflag },
          });
          // toast.error("User Not Registered!");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  // for login form
  const handleLoginSubmit = async e => {
    e.preventDefault();

    // if (validator.isEmail(email)) {
    //   console.log('Valid Email :)')
    // } else {
    //   console.log('Enter valid Email!');
    // }

    // Send the email and hashed password to the backend
    try {
      const response = await axios.post(
        "https://www.cron-media.com:8080/login.php",
        {
          email: email,
          googlesub: password,
        }
      );
      if (response.data.status === true) {
        navigate(
          "/dashboard",
          { state: { email } },
          // localStorage.setItem("userLogin", true),
          toast.success("Welcome To Your Dashboard!")
        );
        Cookies.set("status", "active", { expires: 1 });
        Cookies.set("email", email, { expires: 1 });
      } else if (response.data.code === 1) {
        setError(response.data.message);

        // toast.error("User Not Registered!");
      } else {
        setSignUp(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //password validator
  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = e => {
    const value = e.target.value;
    setPassword(value);
  };

  // signup form
  const handleSignupSubmit = async e => {
    e.preventDefault();

    if (!error && password === confirmPassword) {
      try {
        const googleflag = "0";
        const response = await axios.post(
          "https://www.cron-media.com:8080/loginValidation.php",
          { email }
        );

        if (response.data.status === true) {
          setSignUp(true);
          // toast.error("User already Registered!");
        } else {
          navigate("/register", {
            state: { email, googlesub: password, googleflag },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      {/* Login Logo */}
      <nav className="w-full bg-white fixed top-0 px-4 py-2.5 hidden sm:block">
        <div className="max-w-screen-xl  mx-auto">
          {/* image and buttons */}

          <Link to="/" className="flex">
            <img src={logo} className="h-6 mr-6 sm:h-9" alt="CronMedia Logo" />
          </Link>
        </div>
      </nav>

      <section className="bg-white mx-auto w-full max-w-screen-xl flex flex-col md:flex-row items-center justify-center ">
        <div className="md:w-[70%] flex flex-col lg:flex-row bg-white shadow-xl rounded-2xl overflow-hidden">
          <div className="w-full lg:w-[60%] p-10 bg-gradient-to-bl from-[#1c9dff97] to-[#4e22ff97] text-white flex items-center justify-center">
            <div>
              <p className="text-3xl font-bold font-mango">
                {signup
                  ? "Design is the Silent Ambassador of your Brand"
                  : "Every Interaction, in any form, is Branding."}
              </p>
              <p className="mt-4 text-xl">
                {signup ? "- Paul Rand" : "- Seth Godin"}
              </p>
            </div>
          </div>

          {signup ? (
            // login page
            <div className="w-full lg:w-[40%] p-10 bg-white flex flex-col justify-center">
              <h2 className="text-2xl font-medium mb-4">
                Welcome Back to CronMedia
              </h2>
              <button
                onClick={() => googleLogin()}
                className="bg-white text-black border border-gray-300 rounded-lg px-4 py-2 mb-4 flex items-center justify-center">
                <img
                  src={google}
                  alt="Google"
                  className="w-5 h-5 mr-2"
                />
                Continue with Google
              </button>
              {/* form */}
              <form onSubmit={handleLoginSubmit}>
                {error && (
                  <p className="text-red-500 mb-4 rounded-md border border-red-300 bg-red-200 text-center p-2">
                    {error}
                  </p>
                )}
                <div className="mb-4">
                  <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    placeholder="Password"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-gradient-to-bl from-[#1c9dffcf] to-[#4e22ffcc] text-white rounded-lg px-4 py-2 w-full">
                  Log in
                </button>
              </form>

              <div className="flex justify-between text-left text-xs mt-4">
                <Link to="/forget" className="text-blue-500">
                  Forgot Password
                </Link>
                <Link to="#" className="text-blue-500">
                  Log in with Verification Code
                </Link>
              </div>
              <p className="mt-4 text-center">
                Don’t have an account?{" "}
                <Link
                  onClick={() => setSignUp(!signup)}
                  className="text-blue-500">
                  Sign up here
                </Link>
              </p>
            </div>
          ) : (
            // register page
            <div className="w-full lg:w-[40%] p-10 bg-white flex flex-col justify-center">
              <h2 className="text-2xl font-medium mb-4">
                Welcome to CronMedia
              </h2>
              <button
                onClick={() => googleLogin()}
                className="bg-white text-black border border-gray-300 rounded-lg px-4 py-2 mb-4 flex items-center justify-center">
                <img
                  src={google}
                  alt="Google"
                  className="w-5 h-5 mr-2"
                />
                Continue with Google
              </button>
              <form onSubmit={handleSignupSubmit}>
                <div className="mb-4">
                  <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    placeholder="Password"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    required
                    placeholder="Confirm Password"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                  />
                </div>
                {error && (
                  <p className="text-red-500 mb-4 rounded-md border-red-300 bg-red-200 text-center p-2">
                    {error}
                  </p>
                )}
                <button
                  type="submit"
                  className="bg-gradient-to-bl from-[#1c9dffcf] to-[#4e22ffcc] text-white rounded-lg px-4 py-2 w-full">
                  Signup
                </button>
              </form>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Loginindex;
