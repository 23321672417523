import logo from "../Component/asstes/Images/Property 1=LogoOnWhite.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const AdminLogin = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://www.cron-media.com:8080/adminLogin.php",
        {
          email: email,
          googlesub: password,
        }
      );
      if (response.data.status === true) {
        navigate(
          "/adminPanel",
          { state: { email } },
          toast.success("Logged In!")
        );
        Cookies.set('admin', 'active', { expires: 1 });
      }
      else {
        toast.error("Try Again 👾");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <nav className="w-full  bg-white top-0 px-4 py-2.5  z-50">
        <div className="max-w-screen-xl  mx-auto">
          {/* image and buttons */}

          <Link to="/" className="flex">
            <img src={logo} className="h-6 mr-6 sm:h-9" alt="CronMedia Logo" />
          </Link>
        </div>
      </nav>

      <section className="bg-white mx-auto w-full max-w-screen-xl flex flex-col md:flex-row items-center justify-center mt-32">
        <div className="md:w-[70%] flex flex-col lg:flex-row bg-white shadow-xl rounded-2xl overflow-hidden">
          <div className="w-full lg:w-[60%] p-10 bg-gradient-to-bl from-[#1c9dff97] to-[#4e22ff97] text-white flex items-center justify-center">
            <div>
              <p className="text-3xl font-bold font-mango">
                Be the Change you wish to see in the world.
              </p>
              <p className="mt-4 text-xl">- Mahatma Gandhi</p>
            </div>
          </div>
          <div className="w-full lg:w-[40%] p-10 bg-white flex flex-col justify-center">
            <h2 className="text-2xl font-medium mb-4">
              Welcome Back to CronMedia
            </h2>
            {/* form */}
            <form onSubmit={handleLoginSubmit}>
              <div className="mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  placeholder="Email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  placeholder="Password"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                />
              </div>
              <button
                type="submit"
                className="bg-gradient-to-bl from-[#1c9dffcf] to-[#4e22ffcc] text-white rounded-lg px-4 py-2 w-full">
                Log in
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
