import { useRef, useEffect, useState } from "react";
import axios from "axios";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
import { Link } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import { FiSearch, FiX } from "react-icons/fi";
import Cookies from "js-cookie";

function DashboardHeader({ email, handleData }) {
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [totalcategory, setTotalCategory] = useState([]);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    axios.get("https://www.cron-media.com:8080/category.php").then(response => {
      const catdata = response.data;
      setTotalCategory(catdata.data);
    });

    const getSearchTerm = () => {
      const searchTermCookie = Cookies.get("searchterm");
      const tagSearchCookie = Cookies.get("TagSearch");
      return searchTermCookie && searchTermCookie !== "undefined"
        ? searchTermCookie
        : tagSearchCookie && tagSearchCookie !== "undefined"
        ? tagSearchCookie
        : "";
    };

    inputRef.current.value = getSearchTerm();
    selectRef.current.value = Cookies.get("categoryIndex") || "";
  }, []);

  const handleSearchSubmit = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleData(selectRef.current.value, inputRef.current.value);
      Cookies.remove("searchterm");
      Cookies.remove("TagSearch");
      Cookies.remove("categoryIndex");
    }
  };

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50 h-14 md:h-16">
      <div className="w-full px-4 py-2 flex justify-between items-center">
        {!showSearch && (
          <div className="flex items-center space-x-4">
            <Link to="/dashboard" className="flex">
              <img
                src={logo}
                className="h-6 mr-6 sm:h-9"
                alt="CronMedia Logo"
              />
            </Link>
            {/* <div className="hidden md:flex space-x-4">
              <Link
                to="/dashboard"
                className="block p-4 rounded md:bg-transparent md:p-0 hover:text-blue-700">
                Templates
              </Link>
            </div> */}
          </div>
        )}
        <div
          className={`flex items-center space-x-4 ${
            showSearch ? "w-full" : ""
          }`}>
          <div
            className={`${
              showSearch ? "flex w-full" : "hidden"
            } md:flex md:w-[40vw] lg:w-[60vw] items-center border border-gray-300 rounded-full px-2 py-1`}>
            <input
              type="search"
              ref={inputRef}
              onKeyDown={handleSearchSubmit}
              className="focus:outline-none w-full pl-2"
              placeholder="Search"
            />
            <select
              id="category"
              ref={selectRef}
              className="bg-transparent w-fit h-full border-l-2 text-[#493bfc] text-sm font-bold pl-2 pr-4 mr-2 my-2 outline-none">
              <option value="">All Categories</option>
              {totalcategory.map(category => (
                <option value={category.categoryId} key={category.categoryId}>
                  {category.categoryName}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() => setShowSearch(!showSearch)}
            className="text-blue-600 md:hidden">
            {!showSearch ? <FiSearch size={24} /> : <FiX size={24} />}
          </button>
          {!showSearch && <ProfileDropdown email={email} />}
        </div>
      </div>
    </nav>
  );
}

export default DashboardHeader;
