import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../asstes/Images/Property 1=LogoOnWhite.svg";
import { Link, useNavigate } from "react-router-dom";

function PasswordForget({ showFooter, setShowFooter }) {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);
  const [email, setEmail] = useState("");
  const [verifyOtp, setVerifyOTP] = useState("");
  const [otpInput1, setOptInput1] = useState("");
  const [otpInput2, setOptInput2] = useState("");
  const [otpInput3, setOptInput3] = useState("");
  const [otpInput4, setOptInput4] = useState("");
  const [optBtn, setOtpBtn] = useState(false);

  const navigate = useNavigate();

  const otpInput = otpInput1 + otpInput2 + otpInput3 + otpInput4;

  const submitEmail = async () => {
    const response = await axios.post(
      "https://www.cron-media.com:8080/loginValidation.php",
      { email }
    );
    if (response.data.status === true) {
      await axios
        .post("https://www.cron-media.com:8080/verifyWithOtp.php", { email })
        .then(res => {
          // console.log(res.data.otp, email);
          setVerifyOTP(res.data.otp);
          setOtpBtn(true);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      navigate("/");
    }
  };

  const submitVerifyOtp = () => {
    if (verifyOtp === otpInput) {
      // console.log(true);
      navigate("/reset", { state: email });
    } else {
      console.log(false);
    }
  };

  return (
    <>
      {/* Login Logo */}
      <nav className="w-full  bg-white top-0 px-4 py-2.5  z-50">
        <div className="max-w-screen-xl  mx-auto">
          {/* image and buttons */}

          <Link to="/" className="flex">
            <img src={logo} className="h-6 mr-6 sm:h-9" alt="CronMedia Logo" />
          </Link>
        </div>
      </nav>

      <section className="bg-white mx-auto w-full max-w-screen-xl flex flex-col md:flex-row items-center justify-center mt-32">
        <div className="md:w-[70%] flex flex-col lg:flex-row bg-white shadow-xl rounded-2xl overflow-hidden">
          <div className="w-full lg:w-[60%] p-10 bg-gradient-to-bl from-[#1c9dff97] to-[#4e22ff97] text-white flex items-center justify-center">
            <div>
              <p className="text-3xl font-bold font-mango">
                Be the Change you wish to see in the world.
              </p>
              <p className="mt-4 text-xl">- Mahatma Gandhi</p>
            </div>
          </div>

          <div className="w-full lg:w-[40%] p-10 bg-white flex flex-col justify-center">
            <h2 className="text-2xl font-medium mb-4">
              Welcome Back to CronMedia
            </h2>

            {/* form */}
            <form>
              <div className="mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  placeholder="Enter your Email Id"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 hover:ring-1 hover:ring-blue-200"
                />
                {!optBtn ? (
                  <Link>
                    <button
                      type="submit"
                      onClick={() => {
                        submitEmail();
                      }}
                      className="bg-gradient-to-bl from-[#1c9dffcf] to-[#4e22ffcc] text-white rounded-lg px-4 py-2 w-full mt-4">
                      Get OTP
                    </button>
                  </Link>
                ) : (
                  <>
                    {" "}
                    <div className="flex items-center mt-4 justify-center gap-3">
                      <input
                        type="text"
                        onChange={e => setOptInput1(e.target.value)}
                        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                        pattern="\d*"
                        maxlength="1"
                      />
                      <input
                        type="text"
                        onChange={e => setOptInput2(e.target.value)}
                        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                        maxlength="1"
                      />
                      <input
                        type="text"
                        onChange={e => setOptInput3(e.target.value)}
                        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                        maxlength="1"
                      />
                      <input
                        type="text"
                        onChange={e => setOptInput4(e.target.value)}
                        className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                        maxlength="1"
                      />
                    </div>
                    <div className="max-w-[260px] mx-auto mt-4">
                      <Link
                        type="submit"
                        onClick={submitVerifyOtp()}
                        className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150">
                        Verify Account
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordForget;
