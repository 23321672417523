import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminNavbar from "../AdminNavbar";
import toast from "react-hot-toast";
import withAdminAuth from "../AdminAuth";

const AdminTemplates = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const [templates, setTemplates] = useState([]);
  const [apiUser, setApiUser] = useState([])

  // opens Add Form
  // const openForm = () => {
  //   setIsOpen(!isOpen);
  // };

  // // Opens Update Form
  // const openUpdateForm = id => {
  //   setUpdate(!update);
  //   setId(id);
  // };

  // fetches data

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(
        `https://www.cron-media.com:8080/adminTemplates.php?id=`
      );
      setTemplates(response.data);
      setApiUser(response.data)
      console.log(response);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []); // Removed fetchTemplates from dependency array

  // Delete template
  const handleDelete = async id => {
    try {
      if (window.confirm("Are you sure you want to delete this collection?")) {
        await axios.delete(
          `https://www.cron-media.com:8080/adminTemplates.php?id=${id}`
        );
        fetchTemplates();
        toast.success("Deleted Successfully 🗑️")
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  const handelSearch = (e) => {
    let apiData = [...apiUser]
    console.log(e.target.value);
    const result = apiData.filter((element) => {
        if (e.target.value === '') {
            return true;
        }
        else if (element.Template_Name?.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
        }
        else if (element.Categories?.toLowerCase().includes(e.target.value.toLowerCase())) {
            return true;
        }
        // else if (element.Tags?.toLowerCase().includes(e.target.value.toLowerCase())) {
        //     return true
        // }
        return false

    })
    setTemplates(result)
}

  return (
    <>
      <AdminNavbar />
      <div className="container mx-auto p-4 ">
        <h1 className=" font-mango text-3xl text-center my-4">Templates</h1>
        <div className="relative">
          <input
            type="text"
            placeholder="Search templates"
            className="p-2 border border-gray-300 rounded-md mb-4 w-full"
            onChange={e => handelSearch(e)}
          />
          <Link to={`/addTemplate`}>
            <button className="fixed bottom-8 right-8 bg-blue-500 p-4 rounded-full shadow-lg flex items-center justify-center h-16 w-16">
              <p className="text-center text-6xl text-white">+</p>
            </button>
          </Link>
        </div>

        {/* table */}
        <div className="container mx-auto p-4">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 rounded">
              <thead>
                <tr className="bg-gray-100 border-b">
                  {/* <th className="py-2 px-4 border-r">Template ID</th> */}
                  <th className="py-2 px-4 border-r">Collection ID</th>
                  <th className="py-2 px-4 border-r">Template Name</th>
                  <th className="py-2 px-4 border-r">Small Preview</th>
                  <th className="py-2 px-4 border-r">Large Preview</th>
                  <th className="py-2 px-4 border-r">Template File</th>
                  <th className="py-2 px-4 border-r">Categories</th>
                  <th className="py-2 px-4 border-r">Tags</th>
                  <th className="py-2 px-4 border-r">Number of Assets</th>
                  <th className="py-2 px-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {templates?.map(template => (
                  <tr key={template.ID} className="border-b hover:bg-gray-50 ">
                    {/* <td className="py-2 px-4 border-r">{template.ID}</td> */}
                    <td className="py-2 px-4 border-r">
                      {template.Collection_ID}
                    </td>
                    <td className="py-2 px-4 border-r">
                      {template.Template_Name}
                    </td>
                    <td className="py-2 px-4 border-r">
                      <div className="flex flex-wrap">
                        {template.Small_preview?.map((file, index) => (
                          <div key={index} className="mr-2 mb-2">
                            <img
                              src={`https://www.cron-media.com:8080/Templates/Images/${file}`}
                              alt={file}
                              className="rounded-md h-16 w-16"
                            />
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="py-2 px-4 border-r">
                      <div className="flex flex-wrap">
                        {template.Large_preview?.map((file, index) => (
                          <div key={index} className="mr-2 mb-2">
                            <img
                              src={`https://www.cron-media.com:8080/Templates/Images/${file}`}
                              alt={file}
                              className="rounded-md h-16 w-16"
                            />
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="py-2 px-4 border-r">
                      📂{template.Template_File}
                    </td>
                    <td className="py-2 px-4 border-r">
                      {template.Categories}
                    </td>
                    <td className="py-2 px-4 border-r">
                      {template.Tags?.join(",")}
                    </td>
                    <td className="py-2 px-4 border-r">{template.Assets}</td>
                    <td className="py-2 px-4 flex">
                      <Link
                        to={`/updateTemplate/${template.ID}`}
                        className="bg-green-500 text-white p-2 rounded mr-2 hover:bg-green-600">
                        Update
                      </Link>
                      <button
                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                        onClick={() => handleDelete(template.ID)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAdminAuth(AdminTemplates);
