import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import axios from "axios";
import withAdminAuth from "../AdminAuth";

const UpdateTemplate = ({ showFooter, setShowFooter }) => {
  useEffect(() => {
    setShowFooter(false);
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const { id } = useParams(); // Extract templateID from URL
  const navigate = useNavigate();

  const [collectionID, setCollectionID] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateDesc, setTemplateDesc] = useState("");
  const [smallPreview, setSmallPreview] = useState([]);
  const [largePreview, setLargePreview] = useState([]);
  const [templateFile, setTemplateFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [assets, setAssets] = useState("");
  const [totalcategory, setTotalCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    // Safe parsing of the JSON string
    let parsedCategories = [];
    try {
      parsedCategories = JSON.parse(categories || "[]");
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }

    // Update state with parsed categories
    setSelectedCategories(parsedCategories);
  }, [categories]); // Dependency array ensures effect runs when `categories` changes

  useEffect(() => {
    // Fetch the current template data
    axios
      .get(`https://www.cron-media.com:8080/adminTemplates.php?id=${id}`)
      .then(response => {
        const data = response.data;
        console.log(data[0]);
        setCollectionID(data.Collection_ID);
        setTemplateName(data.Template_Name);
        setTemplateDesc(data.Template_Desc);
        setSmallPreview(data.Small_preview || []);
        setLargePreview(data.Large_preview || []);
        setTemplateFile(data.Template_File);
        setCategories(data.Categories || []);
        setTags(data.Tags || []);
        setAssets(data.Assets);
      });
  }, [id]);

  const onDropTemplateFile = acceptedFiles => {
    setTemplateFile(acceptedFiles[0]);
  };

  const onDropSmallPreview = acceptedFiles => {
    setSmallPreview(acceptedFiles);
  };

  const onDropLargePreview = acceptedFiles => {
    setLargePreview(acceptedFiles);
  };
  const handleCategoryChange = event => {
    const { value, checked } = event.target;

    setSelectedCategories(prevCategories => {
      if (checked) {
        // Add the category if checked
        return [...prevCategories, value];
      } else {
        // Remove the category if unchecked
        return prevCategories.filter(category => category !== value);
      }
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Collection_ID", collectionID);
    formData.append("Template_Name", templateName);
    formData.append("Template_Desc", templateDesc);

    smallPreview.forEach((file, index) => {
      formData.append(`Small_preview[${index}]`, file);
    });

    largePreview.forEach((file, index) => {
      formData.append(`Large_preview[${index}]`, file);
    });

    if (templateFile) {
      formData.append("Template_File", templateFile);
    }

    selectedCategories.forEach((category, index) => {
      formData.append(`Category[${index}]`, category);
    });

    tags.forEach((tag, index) => {
      formData.append(`Tags[${index}]`, tag);
    });
    formData.append("Assets", assets);

    // Show loading toast
    const toastId = toast.loading("Uploading...");

    try {
      await axios.post(
        `https://www.cron-media.com:8080/adminTemplates.php?id=${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Update the loading toast with success message
      toast.success("Collection Updated Successfully ✌️", { id: toastId });
      navigate("/adminTemplates");
    } catch (error) {
      console.error("Error updating template:", error);
      // Update the loading toast with error message
      toast.error("Error updating template", { id: toastId });
    }
  };
  
  // category
  useEffect(() => {
    axios.get(`https://www.cron-media.com:8080/category.php`).then(response => {
      const catdata = response.data;
      console.log(catdata.data);
      setTotalCategory(catdata.data);
      // console.log(totalcategory.categoryName);
      // setTags(data[0].Tags || []);
    });
  }, []);

  toast(
    <alert className="flex flex-col justify-center items-center">
      <h1 className=" font-bold">Rules Of Enterting Data</h1>
      <ul className=" list-decimal">
        <li>In tags enter tags normally without incassing them in brackets </li>
        <li>please comma after every tag</li>
        <li>Dont put same file names or titles</li>
      </ul>
    </alert>,
    {
      duration: 6000,
    }
  );

  return (
    <div className="container max-w-4xl mx-auto p-4 overflow-auto">
      <h1 className="font-mango text-3xl text-center my-4">Update Template</h1>
      <form
        onSubmit={handleSubmit}
        className="space-y-4"
        encType="multipart/form-data">
        <div>
          <label className="block text-gray-700">Collection ID</label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300 rounded"
            value={collectionID}
            // text={collectionID}
            onChange={e => setCollectionID(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-700">Template Name</label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded"
            value={templateName}
            onChange={e => setTemplateName(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-700">Template Description</label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded"
            value={templateDesc}
            onChange={e => setTemplateDesc(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-700">Small Preview</label>
          <Dropzone onDrop={onDropSmallPreview}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 cursor-pointer">
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                <div className="mt-2">
                  {smallPreview.map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))}
                </div>
              </>
            )}
          </Dropzone>
        </div>
        <div>
          <label className="block text-gray-700">Large Preview</label>
          <Dropzone onDrop={onDropLargePreview}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 cursor-pointer">
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                <div className="mt-2">
                  {largePreview.map((file, index) => (
                    <div key={index}>{file.name}</div>
                  ))}
                </div>
              </>
            )}
          </Dropzone>
        </div>
        <div>
          <label className="block text-gray-700">Template File</label>
          <Dropzone onDrop={onDropTemplateFile} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps()}
                  className="border-2 border-dashed p-4 cursor-pointer">
                  <input {...getInputProps()} />
                  <p>Drag & drop a file here, or click to select a file</p>
                </div>
                <div>{templateFile ? <p>{templateFile.name}</p> : null}</div>
              </>
            )}
          </Dropzone>
        </div>
        <div>
          <label className="block text-gray-700">Categories</label>
          <div className="flex flex-wrap">
            {totalcategory.map(category => {
              // Determine if the current category is checked
              const isChecked = selectedCategories.includes(
                category.categoryName
              );

              return (
                <label key={category.categoryName} className="mr-4">
                  <input
                    type="checkbox"
                    name="category"
                    value={category.categoryName}
                    onChange={handleCategoryChange}
                    checked={isChecked} // Use boolean value for `checked`
                  />
                  {category.categoryName}
                </label>
              );
            })}
          </div>
        </div>
        <div>
          <label className="block text-gray-700">Tags</label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded"
            value={tags}
            onChange={e => setTags(e.target.value.split(","))}
          />
        </div>
        <div>
          <label className="block text-gray-700">Assets</label>
          <input
            type="number"
            className="w-full p-2 border border-gray-300 rounded"
            value={assets}
            onChange={e => setAssets(e.target.value)}
          />
        </div>
        <div>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default withAdminAuth(UpdateTemplate);
