import logo from "../../asstes/Images/footerlogowhite.svg";
import facebook from "../../asstes/Images/Facebook.svg";
import twitter from "../../asstes/Images/Twitter.svg";
import youtube from "../../asstes/Images/YouTube.svg";
import instagram from "../../asstes/Images/Instagram.svg";
import linkedIn from "../../asstes/Images/LinkedIn.svg";
import { Link } from "react-router-dom";

function DashboardFooter() {
  return (
    <div>
      <footer className="w-full bg-gray-900">
        <div className="mx-auto w-full">
          <div className="max-w-screen-xl px-5 xl:px-0 mx-auto flex flex-col md:flex-row justify-between items-center gap-4 md:gap-8 py-4 lg:py-8">
            {/* Logo for larger screens */}
            <div className="hidden sm:block">
              <Link to="/">
                <img src={logo} alt="logo" width="150" />
              </Link>
            </div>
            {/* Navigation Links */}
            <div className="text-white flex flex-col sm:flex-row items-center gap-4 lg:gap-8">
              <Link to="/template" className="text-sm">
                Templates
              </Link>
              <Link to="/AboutUs" className="text-sm">
                About Us
              </Link>
              <Link to="/RefundPolicy" className="text-sm">
                Refund Policy
              </Link>
              <Link to="/contact" className="text-sm">
                Contact Us
              </Link>
            </div>
            {/* Logo for smaller screens */}
            <div className="block sm:hidden">
              <img src={logo} alt="logo" width="150" />
            </div>
          </div>
          <div className="px-4 py-4 bg-black">
            <div className="max-w-screen-xl flex flex-col md:flex-row md:items-center md:justify-between mx-auto border-b pb-3 border-gray-500">
              <div className="text-sm flex items-center justify-center gap-4 lg:gap-8 text-gray-300 mb-4 md:mb-0">
                {/* All Rights Reserved |{" "} */}
                <Link to="/TermsConditions">Terms and Conditions</Link>
                <Link to="/PrivacyPolicy"> Privacy Policy</Link>
              </div>
              {/* Social Media Links */}
              <div className="flex justify-center space-x-2 rtl:space-x-reverse">
                <Link to="#" className="text-gray-400 hover:text-white">
                  <img src={facebook} className="h-8" alt="Facebook" />
                </Link>
                <Link to="#" className="text-gray-400 hover:text-white">
                  <img src={twitter} className="h-8" alt="Twitter" />
                </Link>
                <Link to="#" className="text-gray-400 hover:text-white">
                  <img src={instagram} className="h-8" alt="Instagram" />
                </Link>
                <Link to="#" className="text-gray-400 hover:text-white">
                  <img src={linkedIn} className="h-8" alt="LinkedIn" />
                </Link>
                <Link to="#" className="text-gray-400 hover:text-white">
                  <img src={youtube} className="h-8" alt="YouTube" />
                </Link>
              </div>
            </div>
            <p className="text-sm text-gray-300 text-center pt-3">
              Powered by Cronbay Technologies Pvt Ltd © 2024 . All Rights
              Reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default DashboardFooter;
